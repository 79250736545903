import { Col } from 'antd'
import React from 'react'
import { withTranslation } from 'react-i18next'
import history from '../../../../history'

function ActivityMarketPlaceCounter({
  route,
  searchParams,
  totalCount,
  type,
  buyers,
  quantity,
  icon,
  t
}) {
  return (
    <Col lg={3} sm={6} style={{ flex: '1 1 0%' }}>
      <div
        className="report-count-container bluish-background"
        onClick={() => history.push(route, searchParams)}
      >
        <div className="report-count-info">
          <div className="count">
            <span>{totalCount}</span>
            <h3 className="title">{type}</h3>
          </div>
          {/* <hr /> */}
          <div className="activity">
            <h3>
              {buyers} {t('reports:buyers')}
            </h3>
            <h3>
              {quantity} {t('v3:marketplace_quantity')}
            </h3>
          </div>
        </div>
        <div className="report-count-icon">
          <img alt="" src={icon} />
        </div>
      </div>
    </Col>
  )
}

export default withTranslation()(ActivityMarketPlaceCounter)
