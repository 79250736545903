import React from 'react'
import { Col, Divider, Pagination, Row, Select } from 'antd'
import { PaginationRightAligned, ReportSelect, ReportTable } from '../../styled'
import { useTranslation } from 'react-i18next'
import { Search } from '../../../../components'

const UserReportTable = ({
  userType,
  title,
  columns,
  dataSource,
  loading,
  totalUsers,
  page,
  pageSize,
  onChangePagination,
  onSearch,
  handleSearchType,
  handleGroupSearch,
  groups,
  history,
  participantSearchType,
  hostSearchType
}) => {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="user-header">{title.toUpperCase()}</h2>
      <ReportTable
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        borderRadius
        textAlign
        onRow={record => ({
          onClick: () => history.push(`/reports/user/${userType}/${record.id}`)
        })}
        title={() => (
          <>
            <Row
              className="sharelook-report-wrapper sharelook-report-participants-wrapper"
              style={{
                marginLeft: userType === 'participant' ? '15px' : '30px',
                marginTop: '3px'
              }}
            >
              <Col span={13}>
                <div className="reports-user-search">
                  <ReportSelect
                    onChange={handleSearchType}
                    className="rounded-select"
                    dropdownStyle={{ borderRadius: '9px' }}
                    defaultValue={t('reports:all')}
                    arrowColor
                  >
                    <Select.Option
                      value={'search'}
                      className="select-option-user"
                    >
                      {t('reports:all')}
                    </Select.Option>
                    <Select.Option
                      value={'name'}
                      className="select-option-user"
                    >
                      {t('reports:name')}
                    </Select.Option>
                    <Select.Option
                      value={'group_name'}
                      className="select-option-user"
                    >
                      {t('v4:group_name')}
                    </Select.Option>
                    <Select.Option value={'id'} className="select-option-user">
                      {t('reports:id_number')}
                    </Select.Option>
                    <Select.Option
                      value={'email'}
                      className="select-option-user"
                    >
                      {t('reports:email')}
                    </Select.Option>
                  </ReportSelect>

                  {(hostSearchType === 'searchByGroup' ||
                    participantSearchType === 'searchByGroup') &&
                  groups.length > 0 ? (
                    <ReportSelect
                      onChange={handleGroupSearch}
                      className="rounded-select"
                      dropdownStyle={{ borderRadius: '9px' }}
                      arrowColor
                      defaultValue={t('v4:select_group')}
                    >
                      {groups.map(group => (
                        <Select.Option
                          key={group.id}
                          value={group.title}
                          className="select-option-user"
                        >
                          {group.title}
                        </Select.Option>
                      ))}
                    </ReportSelect>
                  ) : (
                    <Search
                      placeholder={t('reports:search_user_report')}
                      onChange={e =>
                        onSearch(
                          userType === 'participant'
                            ? 'participantSearch'
                            : 'hostSearch',
                          e.target.value
                        )
                      }
                    />
                  )}
                </div>
              </Col>
              <Col className="report-title" span={5} push={2}>
                {t('v4:total')} {totalUsers} {title.toUpperCase()}
              </Col>
              <Col
                className="report-pagination"
                span={6}
                style={{ marginTop: '14px' }}
                pull={1}
              >
                <PaginationRightAligned>
                  <Pagination
                    total={dataSource && dataSource.length > 0 ? totalUsers : 0}
                    current={page}
                    pageSize={pageSize}
                    onChange={onChangePagination}
                    size="small"
                    simple
                  />
                </PaginationRightAligned>
              </Col>
            </Row>
            <Divider style={{ margin: '0px' }} />
          </>
        )}
      />
    </>
  )
}

export default UserReportTable
