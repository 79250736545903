import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Spin, Button, Icon, Modal, Alert, Card, Popover, Divider } from 'antd'

import './styles.scss'
import Template from './Template'
import MazeTree from './MazeTree'
import ErrorModal from './ErrorModal'
import { flattenTree, getNestedChildren } from './mazeHelper'

import HideIcon from '../../../../../assets/hide_icon.svg'
import LeftBar from '../../../../../assets/left_arrow_rect.png'
import RightBar from '../../../../../assets/right_arrow_rect.png'
import ChoiceModal from './ChoiceModal'
import Quotes from '../../../Detail/components/SentenceLoader'
import loadingLogo from '../../../Detail/assets'

const ITEMS_PER_PAGE = 20

const TYPES = { SCENARIO: 'Scenario', OPTION: 'Option' }

const defaultState = {
  layerIndex: 1,
  scenarioIndex: 0,
  lastScenarioId: null,
  showChoiceModal: false,
  optionIndex: 0,
  currentTemplateIndex: 0,
  lastScenarioTemplateIndex: 0,
  children: [],
  showLeftPanel: true,
  showRightPanel: true,
  isScenarioActive: false,
  params: [],
  options: [],
  data: [],
  voices: [],
  isLayerMax: false,
  mazeScenarioLoading: true,
  isMazeError: false,
  idealPath: [],
  error: {
    textError: true,
    imageError: true,
    scoreError: true,
    idealPathError: true
  },
  scenarioParents: [],
  zoom: 0.25,
  scenarios: [],
  page: 1
}

class MazeScenario extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    this.setupScenario()
  }

  setupScenario = async () => {
    const { fetching } = this.props
    if (!fetching) {
      // await getWorkshopCourses({ limit: null })
      setTimeout(() => this.checkLayersAndOptions(), 100)
    } else {
      setTimeout(() => this.setupScenario(), 100)
    }
  }

  addScenario = async () => {
    const {
      currentTemplateIndex,
      scenarioIndex,
      lastScenarioId,
      voices
    } = this.state
    const { t } = this.props
    const OPTION_INDEX = 0
    const newScenarioId = lastScenarioId ? lastScenarioId + 1 : 1

    const newTemplates = [
      {
        t,
        key: currentTemplateIndex + 1,
        isScenario: true,
        templateId: currentTemplateIndex + 1,
        scenarioIndex: scenarioIndex + 1,
        onDelete: this.deleteTemplate,
        lastScenarioIndex: newScenarioId,
        onTemplateValue: this.handleTemplateValues,
        voices
      },
      {
        t,
        voices,
        isScenario: false,
        key: currentTemplateIndex + 2,
        templateId: currentTemplateIndex + 2,
        scenarioIndex: scenarioIndex + 1,
        optionIndex: OPTION_INDEX + 1,
        onDelete: this.deleteTemplate,
        lastScenarioIndex: newScenarioId,
        onTemplateValue: this.handleTemplateValues,
        isOptionChecked: false,
        showDeadEndOption: true,
        isDeadEndOption: false,
        handleDeadEnd: this.handleDeadEnd,
        handleScenarioLink: this.handleScenarioLink
      },
      {
        t,
        key: currentTemplateIndex + 3,
        isScenario: false,
        templateId: currentTemplateIndex + 3,
        scenarioIndex: scenarioIndex + 1,
        optionIndex: OPTION_INDEX + 2,
        onDelete: this.deleteTemplate,
        lastScenarioIndex: newScenarioId,
        onTemplateValue: this.handleTemplateValues,
        voices,
        isOptionChecked: false,
        showDeadEndOption: true,
        isDeadEndOption: false,
        handleDeadEnd: this.handleDeadEnd,
        handleScenarioLink: this.handleScenarioLink
      }
    ]

    this.setState(
      {
        children: [...this.state.children, ...newTemplates],
        scenarios: [...this.state.scenarios, scenarioIndex + 1],
        optionIndex: OPTION_INDEX + 2
      },
      () => {
        this.reRenderTemplates(newScenarioId)
        this.setTreeData(TYPES.SCENARIO, newScenarioId)
      }
    )
  }

  setOptionObject = val => ({
    id: val,
    nodeToDraw: true,
    is_dead_end: false
  })

  addOption = async () => {
    const {
      currentTemplateIndex,
      scenarioIndex,
      optionIndex,
      lastScenarioId,
      voices,
      scenarios
    } = this.state
    if (optionIndex === 4) return
    const { t } = this.props

    const newOption = {
      t,
      key: currentTemplateIndex + 1,
      isScenario: false,
      templateId: currentTemplateIndex + 1,
      scenarioIndex,
      optionIndex: optionIndex + 1,
      onDelete: this.deleteTemplate,
      lastScenarioIndex: lastScenarioId,
      lastTemplateIndex: currentTemplateIndex + 1,
      onTemplateValue: this.handleTemplateValues,
      voices,
      isOptionChecked: false,
      showDeadEndOption: true,
      isDeadEndOption: false,
      scenarios,
      handleDeadEnd: this.handleDeadEnd,
      handleScenarioLink: this.handleScenarioLink
    }

    this.setState(
      {
        children: [...this.state.children, newOption],
        optionIndex: optionIndex + 1,
        options: [
          ...this.state.options,
          this.setOptionObject(currentTemplateIndex + 1)
        ]
      },
      () => {
        this.reRenderTemplates(lastScenarioId, currentTemplateIndex + 1)
        this.setTreeData(TYPES.OPTION)
      }
    )
  }

  setTreeData = (type, newScenarioId) => {
    const { data, children, scenarioIndex } = this.state

    // Create a new copy of data instead of reference
    let temp = [...data]

    // Use a non-mutating way to find last scenario
    const lastDataScenario = [...temp]
      .reverse()
      .find(item => item.type === TYPES.SCENARIO)

    const lastItem = temp[temp.length - 1]

    const newScenarioParent = temp.find(item => {
      return (
        item.type === 'Option' &&
        !temp.some(i => i.type === 'Scenario' && i.parent === item.code)
      )
    })

    const dataLength = temp.length

    if (type === TYPES.SCENARIO) {
      const newScenarioCode = lastItem ? lastItem.code + 1 : 1

      const newObjects = [
        {
          code: newScenarioCode,
          type: TYPES.SCENARIO,
          parent: newScenarioParent ? newScenarioParent.code : null,
          values: {}
        },
        {
          code: newScenarioCode + 1,
          type: TYPES.OPTION,
          parent: newScenarioCode,
          values: {}
        },
        {
          code: newScenarioCode + 2,
          type: TYPES.OPTION,
          parent: newScenarioCode,
          values: {}
        }
      ]

      // Create new array instead of mutating
      const newData = [...temp, ...newObjects]

      const newChildren = [...children].map(item => {
        if (item.scenarioIndex === newScenarioId) {
          if (item.isScenario) {
            return {
              ...item,
              data: newObjects[0]
            }
          } else {
            return {
              ...item,
              data: item.optionIndex === 1 ? newObjects[1] : newObjects[2]
            }
          }
        }
        return item
      })

      console.log({
        children,
        newChildren
      })

      this.setState(
        prevState => ({
          data: newData.filter(item => item),
          currentTemplateIndex: dataLength + 3,
          scenarioIndex: prevState.scenarioIndex + 1,
          lastScenarioId: newScenarioId,
          scenarioDeleted: false,
          lastScenarioTemplateIndex: dataLength + 1,
          scenarioParents: [
            ...prevState.scenarioParents,
            this.setOptionObject(dataLength + 2),
            this.setOptionObject(dataLength + 3)
          ],
          children: newChildren.filter(item => item)
        }),
        () => this.checkDeadEnd()
      )
    } else if (type === TYPES.OPTION) {
      const newOptionObject = {
        type: TYPES.OPTION,
        code: lastItem ? lastItem.code + 1 : 1,
        parent: lastDataScenario ? lastDataScenario.code : null,
        values: {}
      }

      // Create new array instead of mutating
      const newData = [...temp, newOptionObject]

      this.setState(
        prevState => ({
          data: newData,
          currentTemplateIndex: dataLength + 1,
          scenarioParents: [
            ...prevState.scenarioParents,
            this.setOptionObject(dataLength + 1)
          ]
        }),
        () => this.checkDeadEnd()
      )
    }
  }

  checkDeadEnd = () => {
    this.setState(
      {
        scenarioParents: this.state.scenarioParents.map(item => {
          if (this.state.data.find(el => el.parent === item.id)) {
            return { ...item, nodeToDraw: false }
          } else return item
        })
      },
      () => {
        this.setState({
          children: this.state.children.map(item => {
            // Check if the item should hide the dead end option
            const shouldHideDeadEnd = this.state.scenarioParents.some(
              el => el.id === item.key && el.nodeToDraw === false
            )

            // Return the updated item with the modified property
            return {
              ...item,
              showDeadEndOption: !shouldHideDeadEnd
            }
          })
        })
      }
    )
  }

  getObject = (name, index, parentIndex, typeIndex) => ({
    code: index + 1,
    type: `${name}`,
    parent: parentIndex,
    index: typeIndex,
    values: {}
  })

  handleTemplateValues = (name, value, id, scenarioIndex = null) => {
    const { data } = this.state
    let parentId = null

    const updatedData = data.map(item => {
      if (item.code === id) {
        parentId = item.parent

        // Handle multiple image selection
        if (name === 'images') {
          const modalObj = { ...item.values }
          delete modalObj.model_id
          delete modalObj.model_obj

          return {
            ...item,
            values: {
              ...modalObj,
              images: [...(item.values.images || []), value]
            }
          }
        }

        // Handle 3D model selection
        if (name === 'model_id') {
          const modalObj = { ...item.values }
          delete modalObj.images
          delete modalObj.image_list

          return {
            ...item,
            values: {
              ...modalObj,
              model_id: value
            }
          }
        }

        // Handle image deletion in multiple image selection
        if (name === 'delImage') {
          return {
            ...item,
            values: {
              ...item.values,
              images: item.values.images.filter(img => img !== value),
              ...(item.values.image_list && {
                image_list: item.values.image_list.filter(
                  img => img.id !== value
                )
              })
            }
          }
        }

        return { ...item, values: { ...item.values, [name]: value } }
      }
      return item
    })

    // Uncheck values for other options of the same scenario
    if (name === 'is_ideal_option' && value === true) {
      const updatedDataArr = updatedData.map(item => {
        if (item.code !== id && item.parent === parentId) {
          return {
            ...item,
            values: { ...item.values, is_ideal_option: !value }
          }
        }
        return item
      })

      const updatedChildren = this.state.children.map(child => {
        if (child.scenarioIndex === scenarioIndex) {
          return {
            ...child,
            isOptionChecked: child.templateId === id
          }
        }
        return child
      })

      this.setState(
        {
          data: updatedDataArr,
          children: updatedChildren
        },
        () => {
          this.handleSubmit()
        }
      )
    } else if (name === 'is_ideal_option') {
      const updatedChildren = this.state.children.map(child => {
        if (child.templateId === id) {
          return {
            ...child,
            isOptionChecked: value
          }
        }
        return child
      })

      this.setState(
        {
          data: updatedData,
          children: updatedChildren
        },
        () => {
          this.handleSubmit()
        }
      )
    } else {
      this.setState({ data: updatedData }, () => {
        this.handleSubmit()
      })
    }
  }

  checkValues = () => {
    const arr = this.state.data
    const isTextError = arr.filter(item => item.values && !item.values.content)
    const isScoreError = arr.filter(
      item => item.type === 'Scenario' && item.values && !item.values.score
    )
    const isImageError = arr.filter(
      item =>
        item.type === 'Scenario' &&
        item.values &&
        (item.values.images
          ? item.values.images.length === 0
          : item.values.model_obj == {} ||
            !item.values.hasOwnProperty('model_id'))
    )

    const textError = isTextError.length !== 0
    const scoreError = isScoreError.length !== 0
    const imageError = isImageError.length !== 0
    this.setState(
      {
        error: {
          textError,
          scoreError,
          imageError,
          idealPathError: true
        },
        isMazeError: textError || scoreError || imageError
      },
      () => {
        this.getIdealPath()
      }
    )
  }

  checkIdealCondition = arr => {
    arr.forEach(item => {
      if (item && item.values.is_ideal_option) {
        const scenario = item.children
        this.setState(
          {
            idealPath: [...this.state.idealPath, item]
          },
          () => {
            if (scenario.length === 0) {
              this.checkTreeContainsIdealPath()
              return
            }
            const scenarioChildren = scenario[0].children
            this.setState(
              {
                idealPath: [...this.state.idealPath, scenario[0]]
              },
              () => this.checkIdealCondition(scenarioChildren)
            )
          }
        )
      }
    })

    if (!arr.some(item => item.values.is_ideal_option === true)) {
      this.checkTreeContainsIdealPath()
    }
  }

  checkTreeContainsIdealPath = () => {
    const { isMazeError } = this.state
    const idealPathErrorCondtion =
      this.state.idealPath.length % 2 !== 0 ||
      this.state.idealPath.filter(
        item =>
          item.type === 'Option' && item.children && item.children.length === 0
      ).length === 0

    if (!idealPathErrorCondtion && !isMazeError) {
      this.goToTraining()
    } else {
      this.setState({
        error: {
          ...this.state.error,
          idealPathError: idealPathErrorCondtion
        },
        isMazeError: true
      })
    }
  }

  getIdealPath = () => {
    const { data } = this.state
    var nest = getNestedChildren(data)
    const JSON = nest[0]
    if (JSON.code === 1) {
      const children = JSON.children
      delete JSON.children
      this.setState(
        {
          idealPath: [JSON]
        },
        () => this.checkIdealCondition(children)
      )
    }
  }

  handleSubmit = async () => {
    const {
      mazeBuilder: {
        mazeImgId,
        mazeTitle,
        mazePassScore,
        mazeOrganiser,
        mazeBackgroundId,
        mazeTags,
        mazeId,
        mazeVoice
      },
      updateMaze,
      mazeBuilderActions: { handleMazeInput }
    } = this.props

    const { data } = this.state

    const mazeData = {
      title: mazeTitle,
      minimum_pass_score: mazePassScore,
      avatar_voice: mazeVoice,
      author: mazeOrganiser,
      cover_id: mazeImgId,
      background_image: mazeBackgroundId,
      topics: JSON.stringify(mazeTags),
      contents: data
    }

    updateMaze({
      id: mazeId,
      data: mazeData
    })
      .then(res => {
        const updatedContents = res.data.contents
        handleMazeInput('mazeContent', updatedContents)
      })

      .catch(error => <Alert type="error" message={error} />)
  }

  checkLayersAndOptions = () => {
    const {
      mazeBuilder: { mazeLayers, mazeOptions, mazeContent }
    } = this.props

    if (mazeContent && mazeContent.length > 0) {
      // const restructuredInput = this.transform(mazeContent)

      this.onEdit(mazeContent)
    } else if (mazeLayers && mazeOptions) {
      this.addCustomLayers()
    } else {
      this.setState({ mazeScenarioLoading: false, showChoiceModal: true })
    }
  }

  transform = input => {
    const extract = (os, nextId = (id => () => String(++id))(0)) =>
      os.map(o => ({
        id: nextId(),
        data: {
          label: Object.fromEntries(
            Object.entries(o).filter(([k, v]) => !Array.isArray(v))
          )
        },
        children: Object.entries(o)
          .filter(([k, v]) => Array.isArray(v))
          .flatMap(([k, v]) => [
            { id: nextId(), data: { label: k }, children: extract(v, nextId) }
          ])
      }))

    const relationships = xs =>
      xs.flatMap(({ id: target, children = [] }) => [
        ...children.map(({ id: source }) => ({
          id: `e${source}-${target}`,
          source,
          target
        })),
        ...relationships(children)
      ])

    const flatten = xs =>
      xs.flatMap(({ children, ...rest }) => [rest, ...flatten(children)])

    const res = extract(input)

    return [...flatten(res), ...relationships(res)].map(i => {
      let obj = { ...i.data.label }

      delete obj.children

      return obj
    })
  }

  addCustomLayers = async () => {
    const {
      mazeBuilder: { mazeLayers, mazeOptions }
    } = this.props

    let layerLastIndex = 1
    let layerIndex = 1
    for (let i = 1; i >= 1; i++) {
      if (i === layerLastIndex * mazeOptions + 1) {
        layerLastIndex = i
        layerIndex++
      }
      await this.addScenario()
      if (mazeOptions > 2) {
        await this.addOption()
        if (mazeOptions === 4) {
          await this.addOption()
        }
      }

      if (layerIndex >= mazeLayers) {
        this.setState({
          mazeScenarioLoading: false,
          isLayerMax: layerIndex === 8
        })
        break
      }
    }
  }

  goBack = () => {
    const {
      history,
      mazeBuilder: { mazeId }
    } = this.props
    history.push(`/maze/${mazeId}/edit`)
  }

  toggleLeftPanel = () => {
    this.setState(
      state => ({
        showLeftPanel: !state.showLeftPanel
      }),
      () => {
        if (!this.state.showLeftPanel) {
          this.setState({ showRightPanel: true })
        }
      }
    )
  }

  toggleRightPanel = () => {
    this.setState(
      state => ({
        showRightPanel: !state.showRightPanel
      }),
      () => {
        if (!this.state.showRightPanel) {
          this.setState({ showLeftPanel: true })
        }
      }
    )
  }

  deleteTemplate = params => {
    const { isScenario, scenarioIndex, templateId } = params
    const { scenarioParents, data, scenarios, children } = this.state

    // Handle data filtering based on whether it's a scenario or option
    const newData = data.filter((item, index) => {
      if (isScenario) {
        // For scenarios, delete all items from templateId onwards
        // This removes the scenario and all its options
        return index < templateId
      } else {
        // For options, only delete the specific item
        return index !== templateId
      }
    })

    // Handle children filtering
    const filteredChildren = children.filter(item => {
      if (isScenario) {
        // Remove all children associated with this scenario
        return item.scenarioIndex !== scenarioIndex
      } else {
        // Remove only the specific option
        return item.templateId !== templateId
      }
    })

    // Handle scenario parents (options) filtering
    let options = []
    if (isScenario) {
      // When deleting a scenario, remove all its associated options
      const optionsToDelete = this.getNumberOfOptions(templateId)
      const optionArr =
        scenarioParents &&
        scenarioParents
          .reverse()
          .slice(optionsToDelete, scenarioParents.length)
          .reverse()

      // Update node drawing status for the first remaining option
      let count = 0
      options = optionArr
        .reverse()
        .map(item => {
          if (item.nodeToDraw === false && count === 0) {
            count = 1
            return { ...item, nodeToDraw: true }
          }
          return item
        })
        .reverse()
    } else {
      // When deleting an option, only remove that specific option
      options = [...scenarioParents]
      options.pop()
    }

    // Get last template and scenario information
    const lastTemplate = filteredChildren[filteredChildren.length - 1]
    const lastScenarioTemplate = filteredChildren
      .reverse()
      .find(item => item.isScenario === true)

    // Update scenarios array
    const filteredScenarios = isScenario
      ? scenarios.filter(item => item !== scenarioIndex)
      : scenarios

    // Update children with new information
    const newChildren = filteredChildren
      .reverse()
      .map(item => ({
        ...item,
        lastScenarioIndex: isScenario ? scenarioIndex - 1 : scenarioIndex,
        lastTemplateIndex: lastTemplate.templateId,
        scenarios: filteredScenarios
      }))
      .filter(item => item !== undefined)

    // Update state with all changes
    this.setState(
      {
        scenarioIndex: isScenario ? scenarioIndex - 1 : scenarioIndex,
        lastScenarioId: isScenario ? scenarioIndex - 1 : scenarioIndex,
        children: newChildren,
        lastScenarioTemplateIndex: isScenario
          ? lastScenarioTemplate.templateId
          : this.state.lastScenarioTemplateIndex,
        optionIndex: lastTemplate.optionIndex,
        data: newData,
        currentTemplateIndex: lastTemplate.templateId,
        scenarioDeleted: isScenario,
        scenarioParents: options,
        scenarios: filteredScenarios
      },
      () => this.handleSubmit()
    )
  }
  onEdit = async data => {
    let parent = null
    let count = 0
    let newTemplates = []
    let newOptions = []
    let newScenarios = []
    let scenarioParents = []
    let scenarios = []
    let newOptionIndex = this.state.optionIndex
    let currentTemplateIndex = this.state.currentTemplateIndex
    let scenarioIndex = this.state.scenarioIndex
    let lastScenarioId = null
    let voices = this.state.voices
    const { t } = this.props

    for (let i = 0; i < data.length; i++) {
      const element = data[i]
      if (element.type === 'Scenario') {
        count = 0
        newOptionIndex = 0
        const totalScenarioData = [data[i], data[i + 1], data[i + 2]]
        const newScenarioId = lastScenarioId ? lastScenarioId + 1 : 1

        let isOptionChecked = {
          one: false,
          two: false
        }

        const dataOpt1 = totalScenarioData[1]
        const dataOpt2 = totalScenarioData[2]

        const valueOpt1 = dataOpt1 && dataOpt1.values
        const valueOpt2 = dataOpt2 && dataOpt2.values

        if (valueOpt1 && valueOpt1.hasOwnProperty('is_ideal_option'))
          isOptionChecked.one = valueOpt1.is_ideal_option
        if (valueOpt2 && valueOpt2.hasOwnProperty('is_ideal_option'))
          isOptionChecked.two = valueOpt2.is_ideal_option

        newTemplates.push(
          {
            t,
            key: currentTemplateIndex + 1,
            isScenario: true,
            templateId: currentTemplateIndex + 1,
            scenarioIndex: scenarioIndex + 1,
            onDelete: this.deleteTemplate,
            lastScenarioIndex: newScenarioId,
            onTemplateValue: this.handleTemplateValues,
            data: totalScenarioData[0],
            voices
          },
          {
            t,
            key: currentTemplateIndex + 2,
            isScenario: false,
            templateId: currentTemplateIndex + 2,
            scenarioIndex: scenarioIndex + 1,
            optionIndex: 1,
            onDelete: this.deleteTemplate,
            lastScenarioIndex: newScenarioId,
            onTemplateValue: this.handleTemplateValues,
            data: dataOpt1,
            voices,
            isOptionChecked: isOptionChecked.one,
            showDeadEndOption: true,
            isDeadEndOption: dataOpt1 ? dataOpt1.is_dead_end : false,
            handleDeadEnd: this.handleDeadEnd,
            handleScenarioLink: this.handleScenarioLink
          },
          {
            t,
            key: currentTemplateIndex + 3,
            isScenario: false,
            templateId: currentTemplateIndex + 3,
            scenarioIndex: scenarioIndex + 1,
            optionIndex: 2,
            onDelete: this.deleteTemplate,
            lastScenarioIndex: newScenarioId,
            onTemplateValue: this.handleTemplateValues,
            data: dataOpt2,
            voices,
            isOptionChecked: isOptionChecked.two,
            showDeadEndOption: true,
            isDeadEndOption: dataOpt2 ? dataOpt2.is_dead_end : false,
            handleDeadEnd: this.handleDeadEnd,
            handleScenarioLink: this.handleScenarioLink
          }
        )

        newScenarios.push(scenarioIndex + 1)
        parent = currentTemplateIndex + 1
        scenarioParents.push(this.setOptionObject(currentTemplateIndex + 1))

        // Update lastScenarioId and scenarioIndex
        lastScenarioId = newScenarioId
        scenarioIndex += 1 // Increment scenarioIndex
        currentTemplateIndex += 3 // Update template index to the next available value
        newOptionIndex += 2
      } else if (element.type === 'Option') {
        if (element.parent === parent) {
          count = count + 1
          if (count === 3 || count === 4) {
            const optionData = [element]
            if (newOptionIndex === 4) return

            let isChecked = false
            const data = optionData && optionData[0]

            if (
              data &&
              data.values &&
              data.values.hasOwnProperty('is_ideal_option')
            ) {
              isChecked = data.values.is_ideal_option
            }

            const newOption = {
              t,
              key: currentTemplateIndex + 1,
              isScenario: false,
              templateId: currentTemplateIndex + 1,
              scenarioIndex,
              optionIndex: newOptionIndex + 1,
              onDelete: this.deleteTemplate,
              lastScenarioIndex: lastScenarioId,
              lastTemplateIndex: currentTemplateIndex + 1,
              onTemplateValue: this.handleTemplateValues,
              voices,
              data: optionData[0],
              isOptionChecked: isChecked,
              showDeadEndOption: true,
              isDeadEndOption: data ? data.is_dead_end : false,
              scenarios: this.state.scenarios,
              handleDeadEnd: this.handleDeadEnd,
              handleScenarioLink: this.handleScenarioLink
            }

            newTemplates.push(newOption)
            newOptions.push(newOptionIndex + 1)
            newOptionIndex += 1
            currentTemplateIndex += 1

            scenarioParents.push(this.setOptionObject(currentTemplateIndex + 1))
          }
        }
      }
    }
    this.setState(
      {
        mazeScenarioLoading: false,
        children: [...this.state.children, ...newTemplates],
        scenarios: [...this.state.scenarios, ...newScenarios],
        options: [...this.state.options, ...newOptions],
        optionIndex: newOptionIndex,
        lastScenarioId,
        currentTemplateIndex,
        scenarioParents,
        scenarioIndex,
        data
      },
      () => {
        this.reRenderTemplates(lastScenarioId, currentTemplateIndex)
      }
    )
  }

  getNumberOfOptions = index => {
    const data = this.state.data.filter(item => item.parent === index)
    return data.length
  }

  reRenderTemplates = (id, lastTemplateIndex) => {
    const updatedChildren = this.state.children.map(item => ({
      ...item,
      lastScenarioIndex: id,
      lastTemplateIndex: lastTemplateIndex || ''
    }))

    this.setState({ children: updatedChildren })
  }

  goToTraining = () => {
    const {
      history,
      mazeBuilder: { mazeId }
    } = this.props
    history.push(`/maze/${mazeId}/edit/publish`)
  }

  goToTemplate = code => {
    const el = document.getElementById(`maze-template-${code}`)

    if (el) {
      const container = document.querySelector('.create-maze__left-contents')
      container.scrollTop = el.offsetTop - el.getBoundingClientRect().height

      el.classList.remove('maze-template-blink')

      setTimeout(function() {
        el.classList.add('maze-template-blink')
      }, 10)
    }
  }

  handleDeadEnd = (code, isUnmark = false) => {
    const parents = this.state.scenarioParents.map(item => {
      if (item.id === code) {
        return { ...item, is_dead_end: isUnmark ? false : true }
      } else {
        return item
      }
    })

    const data = this.state.data.map(item => {
      if (item.code === code) {
        return { ...item, is_dead_end: isUnmark ? false : true }
      } else {
        return item
      }
    })

    const updatedChildren = this.state.children.map((item, index) => ({
      ...item,
      data: data[index]
    }))

    this.setState(
      {
        scenarioParents: parents,
        data,
        children: updatedChildren
      },
      () => {
        this.handleSubmit()
        this.checkDeadEnd()
      }
    )
  }

  handleScenarioLink = (linkedScenarioId, templateId) => {
    this.setState(
      {
        data: this.state.data.map(item => {
          if (item.code === templateId) {
            return { ...item, possible_scenario_code: linkedScenarioId }
          }
          return item
        })
      },
      () => this.handleSubmit()
    )
  }

  closeChoiceModal = () => {
    this.setState({ showChoiceModal: false }, () => this.addScenario())
  }

  onMazeGenerated = dataArray => {
    this.setState(
      {
        ...defaultState,
        showChoiceModal: false,
        mazeScenarioLoading: true
      },
      () => {
        window.location.reload()
      }
    )
  }

  handleLoadMore = () => {
    this.setState(prevState => ({
      page: prevState.page + 1
    }))
  }

  getDisplayItems = children => {
    const lastItem = children[children.length - 1]
    const { page } = this.state

    let lastItemOptions = children.filter(
      item => item.scenarioIndex === lastItem.scenarioIndex
    )

    let displayedItemsWithoutLastItemOptions = children.filter(
      item => item.scenarioIndex !== lastItem.scenarioIndex
    )

    if (
      lastItem &&
      !lastItem.data &&
      lastItem.optionIndex &&
      lastItem.optionIndex < 3
    ) {
      const getScenarioIndexOfLast = lastItem.scenarioIndex - 1

      const items = children.filter(
        item => item.scenarioIndex === getScenarioIndexOfLast
      )

      lastItemOptions = [...items, ...lastItemOptions]
      displayedItemsWithoutLastItemOptions = children.filter(
        item =>
          item.scenarioIndex !== getScenarioIndexOfLast &&
          item.scenarioIndex !== lastItem.scenarioIndex
      )
    }

    let displayedItems = displayedItemsWithoutLastItemOptions.slice(
      0,
      page * ITEMS_PER_PAGE
    )

    if (displayedItems.length < 1 && lastItemOptions.length < 1)
      displayedItems = children

    return { displayedItems, lastItemOptions }
  }

  render() {
    const {
      t,
      maze: { updatingMaze },
      fetching
    } = this.props
    const {
      data,
      children,
      isLayerMax,
      isMazeError,
      showLeftPanel,
      showRightPanel,
      showChoiceModal,
      mazeScenarioLoading
    } = this.state

    const isNodeToDraw = this.state.scenarioParents.find(
      item => item.nodeToDraw && !item.is_dead_end
    )

    const { displayedItems, lastItemOptions } = this.getDisplayItems(children)

    const legend = (
      <>
        <div className="maze-tree-wrapper__legend__container">
          <div className="maze-tree-wrapper__legend ">
            <div className="maze-tree-wrapper__legend__type">
              Tree Node Type
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape blue"></div>
              <span>Correct Option or Scenario</span>
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape green"></div>
              <span>Incorrect Option</span>
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape white"></div>
              <span>Option or Scenario without any text in it</span>
            </div>

            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape red"></div>
              <span>Dead End (Red Border)</span>
            </div>
          </div>

          <div className="maze-tree-wrapper__legend">
            <div className="maze-tree-wrapper__legend__type">Paths</div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__path blue">
                &#8594;
              </div>
              <span>Correct Path</span>
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__path black">
                &#8594;
              </div>
              <span>
                Incorrect Path / Path where correct option is not selected.
              </span>
            </div>
          </div>
        </div>

        <br />

        <Alert
          type="info"
          message="When you double-click on a tree node, the text editor will automatically scroll to display the content of that specific node in the view."
        />
      </>
    )

    if (!fetching && showChoiceModal) {
      return (
        <ChoiceModal
          onClose={this.closeChoiceModal}
          onMazeGenerated={this.onMazeGenerated}
        />
      )
    }

    if (fetching)
      return <Spin className="maze__loader" tip="Fetching data..." />

    if (mazeScenarioLoading)
      return (
        <div className="maze__loader">
          <Alert
            type="info"
            message="Please wait while we are generating the maze."
          />
          <hr />
          <img
            alt=""
            id="shl-animate-logo"
            className="shl-animate-logo"
            src={loadingLogo}
          />
          <Quotes />
        </div>
      )

    const itemsLeftToRender =
      children.length - (displayedItems.length + lastItemOptions.length)

    return (
      <>
        <section className="section">
          <div className="create-maze">
            <div
              className={`${
                !showRightPanel && showLeftPanel
                  ? 'show__left'
                  : showLeftPanel
                  ? ''
                  : 'hide__panel'
              } create-maze__left `}
            >
              <div className="hide__map" onClick={this.toggleLeftPanel}>
                <img alt="" src={HideIcon} />
                Hide
              </div>
              <div className="create-maze__left-contents">
                {displayedItems.map(item => (
                  <Template key={item.key} {...item} />
                ))}
                {displayedItems.length + lastItemOptions.length <
                  children.length && (
                  <Divider onClick={this.handleLoadMore}>
                    <div className="maze-load-more">
                      Load {itemsLeftToRender > 20 ? '20' : itemsLeftToRender}{' '}
                      More ({itemsLeftToRender} items left) <Icon type="down" />
                    </div>
                  </Divider>
                )}

                {lastItemOptions && lastItemOptions.length && (
                  <>
                    {lastItemOptions.map(item => (
                      <Template key={item.key} {...item} />
                    ))}
                  </>
                )}
                <div className="maze__action">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.addScenario}
                    disabled={isLayerMax || !isNodeToDraw}
                  >
                    Add Scenario
                  </Button>
                  <Button
                    type="default"
                    shape="round"
                    onClick={this.addOption}
                    disabled={this.state.optionIndex === 4 || isLayerMax}
                  >
                    Add Option
                  </Button>
                </div>
              </div>
            </div>
            {!showLeftPanel && (
              <img
                alt=""
                className="hide_bar"
                src={LeftBar}
                onClick={this.toggleLeftPanel}
              />
            )}
            <div
              className={`${
                !showLeftPanel && showRightPanel
                  ? 'show__right'
                  : showRightPanel
                  ? ''
                  : 'hide__panel'
              } create-maze__right `}
            >
              <div className="hide__map" onClick={this.toggleRightPanel}>
                <img alt="" src={HideIcon} />
                Hide
              </div>

              <MazeTree
                data={data}
                {...this.props}
                showRightPanel={showRightPanel}
                goToTemplate={this.goToTemplate}
                markDeadEnd={this.handleDeadEnd}
                unmarkDeadEnd={code => this.handleDeadEnd(code, true)}
              />

              <div className="maze-tree-wrapper__bottom">
                <Popover content={legend} title="Legend" trigger="click">
                  <Icon type="info" />
                </Popover>
              </div>
            </div>
            {!showRightPanel && (
              <img
                alt=""
                className="hide_bar"
                src={RightBar}
                onClick={this.toggleRightPanel}
              />
            )}
          </div>
        </section>

        <div className="p-footer">
          <div className="p-footer__inner p-footer__inner_justify">
            <div className="p-footer__col">
              <div className="p-footer__item">
                <Button className="rounded" onClick={this.goBack}>
                  Back
                </Button>
              </div>
            </div>
            <div className="p-footer__col">
              <div className="p-footer__item">
                <Button
                  className="rounded"
                  loading={updatingMaze}
                  onClick={this.handleSubmit}
                >
                  {t('buttons:save_for_later')}{' '}
                </Button>
              </div>
              <div className="p-footer__item">
                <Button
                  type="primary"
                  className="rounded"
                  onClick={this.checkValues}
                >
                  {t('buttons:publish')}
                  <Icon type="right" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        {isMazeError && (
          <Modal
            className="ant-error-modal"
            visible={isMazeError}
            onCancel={() => this.setState({ isMazeError: false })}
            footer={false}
            centered={true}
          >
            <ErrorModal error={this.state.error} />
          </Modal>
        )}
      </>
    )
  }
}

export default withTranslation('mazescenario')(MazeScenario)
