import React from 'react'
import { DatePicker, Input } from 'antd'
import { Row, Col, Field } from '../../../../../styles'
import { withTranslation } from 'react-i18next'
import './styles.scss'

const NumericInput = props => {
  const onChange = e => {
    const { value } = e.target
    const reg = /^(0|[1-9][0-9]*)$/
    if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
      props.onChange(value)
    }
  }

  return <Input {...props} onChange={onChange} maxLength={25} />
}

const EDatePicker = props => {
  const onChange = (field, value) => {
    const { onChange } = props

    if (onChange) {
      onChange(field, value)
    }
  }

  const onTimeChange = value => {
    onChange('eventTime', value)
  }

  const onDurationChange = value => {
    onChange('eventDuration', value)
  }

  console.log(props)
  const {
    eventTime,
    eventDuration,
    t,
    disabledDate,
    isShowNoted,
    current: { is_live, is_finished }
  } = props

  return (
    <Row className="wrap_date_picker">
      <Col md={4}>
        <Field>
          <Field.Label>{t('labels:event_date')}</Field.Label>
          <Field.Control className="eventDatePicketContainer">
            <DatePicker
              style={{ width: '100%' }}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={eventTime}
              placeholder={t('placeholders:date_event')}
              onChange={onTimeChange}
              disabledDate={disabledDate ? disabledDate : false}
              disabled={is_live || is_finished}
              suffixIcon={null}
            />
          </Field.Control>
        </Field>
      </Col>
      <Col md={2}>
        <Field>
          <Field.Label>{t('labels:duration')}</Field.Label>
          <Field.Control>
            <NumericInput
              style={{ width: '100%' }}
              value={eventDuration}
              placeholder={t('placeholders:minutes')}
              onChange={onDurationChange}
              disabled={is_live || is_finished}
            />
          </Field.Control>
        </Field>
      </Col>
      {isShowNoted && <span>{t('v4:event_duration_message')}</span>}
    </Row>
  )
}

export default withTranslation()(EDatePicker)
