import React from 'react'
import { Row, Col, Form, Input, Checkbox } from 'antd'
import './index.css'

const BottomSection = props => {
  const {
    form: { getFieldDecorator },
    mazeBuilder: {
      mazeDescription,
      mazeOutline,
      mazeObjectives,
      mazeShareProfile,
      mazeMarketPlaceDescription
    },
    onDescription,
    onObjectives,
    onOutline,
    onShareProfile,
    onMarketPlaceDescription,
    isMarketPlace,
    t
  } = props

  const handleDescription = (val, field) => {
    switch (field) {
      case 'description':
        onDescription(val)
        break
      case 'maze_obj':
        onObjectives(val)
        break
      case 'maze_outline':
        onOutline(val)
        break
      case 'maze_description':
        onMarketPlaceDescription(val)
        break
      default:
        break
    }
  }

  const generateEditor = (title, params, description) => {
    return (
      <div className="margin_top ">
        <Form.Item label={title} colon={false} required={false}>
          {getFieldDecorator(params, {
            rules: [
              {
                required: params === 'description' ? false : true,
                message: t(`v4:${params}`)
              }
            ],
            initialValue: description
          })(
            <Input.TextArea
              placeholder={t('v4:text')}
              autoSize={{ minRows: 5, maxRows: 20 }}
              onKeyDown={e => {
                if (
                  e.keyCode === 32 &&
                  (e.target.value.charAt(e.target.selectionStart - 1) === ' ' ||
                    e.target.value.charAt(e.target.selectionStart) === ' ')
                ) {
                  e.preventDefault()
                }
              }}
              onChange={e => handleDescription(e.target.value, params)}
              style={{ fontSize: '1.6rem', resize: 'none' }}
              wrap={'soft'}
            />
          )}
        </Form.Item>
      </div>
    )
  }
  return (
    <div style={{ width: '100%' }}>
      <Row className="quill-div_row">
        <Col span={24}>
          {isMarketPlace && (
            <>
              {generateEditor('Maze Objectives', 'maze_obj', mazeObjectives)}
              {generateEditor('Maze Outline', 'maze_outline', mazeOutline)}
              {generateEditor(
                'Description',
                'maze_description',
                mazeMarketPlaceDescription
              )}
            </>
          )}
          {!isMarketPlace &&
            generateEditor('Description', 'description', mazeDescription)}
        </Col>
      </Row>
      {isMarketPlace && (
        <Form.Item colon={false} required={false}>
          {getFieldDecorator('is_shared_profile', {
            valuePropName: 'checked',
            initialValue: mazeShareProfile
          })(
            <Checkbox
              className="marketplace__checkbox"
              onChange={e => onShareProfile(e.target.checked)}
            >
              {t('v3:allow_sharing_trainer_info')}
            </Checkbox>
          )}
        </Form.Item>
      )}
    </div>
  )
}

export { BottomSection }
