import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, Divider, Icon, Spin, Tooltip, message } from 'antd'
import './styles.scss'
import {
  getScenarioData,
  putScenarioAttempt
} from '../../../../../services/api/courses'
import ReactPlayer from 'react-player'
import _ from 'lodash'

function ScenarioView(props) {
  const {
    content: { content: scenarioId, height, width },
    scale
  } = props

  const audioRef = useRef(new Audio())
  const videoRef = useRef(null)

  const [isFetching, setIsFetching] = useState(true)
  const [scenarioData, setScenarioData] = useState({})
  const [isAudioPlaying, setIsAudioPlaying] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})
  const [attempts, setAttempts] = useState({
    limit: 0,
    count: 0
  })
  const [saving, setSaving] = useState(false)
  const [isStoryVisible, setIsStoryVisible] = useState(true)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [showControls, setShowControls] = useState(false)

  useEffect(() => {
    if (scenarioId) {
      fetchScenarioData(scenarioId)
    }

    return () => {
      // Cleanup audio when component unmounts
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current = null
      }
    }
  }, [scenarioId])

  const fetchScenarioData = useCallback(async id => {
    try {
      const res = await getScenarioData(id)
      if (_.isEmpty(res.data)) {
        return
      }
      setScenarioData(res.data)
      setAttempts({
        count: res.data.scenario_attempt
          ? res.data.scenario_attempt.attempts
          : 0,
        limit: res.data.attempts_count
      })
    } catch (err) {
      message.error(err.message)
    } finally {
      setIsFetching(false)
    }
  }, [])

  useEffect(() => {
    if (scenarioData && scenarioData.story_media && audioRef.current) {
      const audioLink = scenarioData.story_media.link
      if (audioLink) {
        audioRef.current.src = audioLink
        audioRef.current.load()
        audioRef.current.play()
        setIsAudioPlaying(true)
      }
    }
  }, [scenarioData])

  useEffect(() => {
    const handleAudioEnded = () => {
      setIsAudioPlaying(false)
    }

    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleAudioEnded)
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleAudioEnded)
      }
    }
  }, [])

  const togglePlayAudio = useCallback(() => {
    if (isAudioPlaying) {
      audioRef.current.pause()
      setIsAudioPlaying(false)
    } else {
      audioRef.current.play()
      setIsAudioPlaying(true)
    }
  }, [isAudioPlaying])

  const isAttemptsLimitReached = attempts.count >= attempts.limit

  const handleOptionClick = option => {
    if (selectedOption.answer || saving) {
      return
    }

    setSelectedOption(option)

    if (isAttemptsLimitReached) {
      return
    }

    setAttempts(prev => ({ ...prev, count: prev.count + 1 }))
    updateAnswer(option)
  }

  useEffect(() => {
    if (selectedOption.answer) {
      const container = document.querySelector('.scenario-view__container')
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        })
      }
    }
  }, [selectedOption.answer])

  const updateAnswer = useCallback(
    async option => {
      try {
        setSaving(true)
        const data = {
          answer_uid: option.uid
        }
        await putScenarioAttempt(scenarioId, data)
      } catch (err) {
        message.error(err.message)
      } finally {
        setSaving(false)
      }
    },
    [scenarioId]
  )

  const isVideo =
    scenarioData &&
    scenarioData.media &&
    scenarioData.media.media_format.includes('VIDEO')

  const onVideoPause = () => {
    setIsVideoPlaying(false)
    setIsStoryVisible(true)
    setShowControls(false)

    if (videoRef.current) {
      videoRef.current.getInternalPlayer().pause()
    }
  }

  const onVideoPlay = () => {
    setIsVideoPlaying(true)
    setIsStoryVisible(false)
    setShowControls(true)

    if (videoRef.current) {
      videoRef.current.getInternalPlayer().play()
    }
  }

  const onStoryClick = () => {
    setIsStoryVisible(prev => !prev)

    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.getInternalPlayer().pause()
        setShowControls(false)
      } else {
        videoRef.current.getInternalPlayer().play()
        setShowControls(true)
      }
    }
    setIsVideoPlaying(prev => !prev)
  }

  const isCorrectAnswerSelected =
    scenarioData.answers &&
    scenarioData.answers.some(
      answer => answer.uid === selectedOption.uid && answer.correct
    )

  if (isFetching) {
    return (
      <div className="spin-container">
        <Spin size="large" tip="Loading Scenario..." />
      </div>
    )
  }

  if (!isFetching && _.isEmpty(scenarioData)) {
    return (
      <div className="spin-container">
        <Alert type="error" message="Scenario not found" />
      </div>
    )
  }

  const containerWidth = scale ? width * scale : width
  const containerHeight = scale ? height * scale : height

  return (
    <div
      className="scenario-view__container"
      style={{
        height: containerHeight,
        width: containerWidth
      }}
    >
      <div className="scenario-view__items">
        {scenarioData.story_media && (
          <Tooltip title={isAudioPlaying ? 'Pause Audio' : 'Play Audio'}>
            <div
              className={`scenario-view__item sound ${
                isAudioPlaying ? 'playing' : ''
              }`}
              onClick={togglePlayAudio}
            >
              {isAudioPlaying ? (
                <Icon type="sound" theme="twoTone" />
              ) : (
                <Icon type="sound" />
              )}
            </div>
          </Tooltip>
        )}

        {isVideo && (
          <Tooltip title={isVideoPlaying ? 'Pause Video' : 'Play Video'}>
            <div
              className="scenario-view__item video"
              onClick={isVideoPlaying ? onVideoPause : onVideoPlay}
            >
              {isVideoPlaying ? (
                <Icon type="pause" />
              ) : (
                <Icon type="play-circle" />
              )}
            </div>
          </Tooltip>
        )}

        {isVideo && !isStoryVisible && (
          <Tooltip title={isStoryVisible ? 'Hide Story' : 'Show Story'}>
            <div className="scenario-view__item story" onClick={onStoryClick}>
              <Icon type="book" />
            </div>
          </Tooltip>
        )}
      </div>

      <div className="scenario-view__content">
        <div className="scenario-view__content-left">
          <div className="scenario-view__content-left-media">
            {isVideo ? (
              <ReactPlayer
                className="react-player"
                responsive
                width="100%"
                url={scenarioData.media && scenarioData.media.link}
                controls={showControls}
                ref={videoRef}
                playing={isVideoPlaying}
                onEnded={onVideoPause}
              />
            ) : (
              <img src={scenarioData.media && scenarioData.media.link} alt="" />
            )}
          </div>
          {isStoryVisible && (
            <div className="scenario-view__content-left-text custom-scrollbar">
              <h3>{scenarioData.name}</h3>
              <div className="scenario-view__content-left-text-story">
                {scenarioData.story}
              </div>
            </div>
          )}
        </div>
        <div className="scenario-view__content-right">
          <h3 className="scenario-view__content-right-title">
            Study the scenario on this page and select the correct answer from
            the options below.
          </h3>
          <div className="scenario-view__content-right-options">
            {scenarioData.answers.map((option, index) => {
              const isCorrectAnswer = selectedOption.answer && option.correct
              const isIncorrectOption =
                selectedOption.uid === option.uid && !option.correct

              return (
                <div
                  key={index}
                  className={`scenario-view__content-right-option ${
                    isCorrectAnswer && isAttemptsLimitReached
                      ? 'correct'
                      : isIncorrectOption
                      ? 'wrong'
                      : ''
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <p>{option.answer}</p>
                </div>
              )
            })}
          </div>
          {selectedOption.answer && (
            <div className="scenario-view__content-right-action">
              <div className="scenario-view__content-right-attempts">
                {!isCorrectAnswerSelected && (
                  <>
                    <Icon
                      type="exclamation-circle"
                      style={{ color: 'red', fontSize: '12px' }}
                    />{' '}
                    {isAttemptsLimitReached
                      ? 'Attempts limit reached. You can play again but your score will not be saved.'
                      : `${attempts.limit - attempts.count} attempts remaining`}
                  </>
                )}
              </div>

              <Button
                type="primary"
                ghost
                loading={saving}
                shape="round"
                size="large"
                onClick={() => setSelectedOption({})}
                className="scenario-view__content-right-button"
              >
                Play Again
              </Button>
            </div>
          )}
        </div>
      </div>
      {selectedOption.answer && (
        <div className="scenario-view__rating">
          <p>{scenarioData.feedback}</p>
        </div>
      )}
    </div>
  )
}

export default ScenarioView
