import styled, { css } from 'styled-components'
import { Form, Radio, Checkbox } from 'antd'

import { styleguide } from '../../../../../../constants'

const { colors } = styleguide

const Constructor = styled.div`
  .ant-spin-nested-loading {
    width: 100%;
  }
`
Constructor.Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
`
Constructor.Label = styled.span`
  font-size: 15px;
  color: #246cf6;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

Constructor.Title = styled.div`
  color: ${colors.black};
  font-weight: 700;
  display: block;
  margin-bottom: 2rem;
  font-size: 15px;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -8px;
    left: 0;
    background: #000;
    opacity: 0.1;
  }
`

Constructor.Name = styled.span`
  font-weight: 500;
`

Constructor.BoldName = styled.span`
  font-weight: 700;
  font-size: 15px;
`

Constructor.RadioGroup = styled(Radio.Group)`
  && {
    margin-bottom: 2rem;
  }
`

Constructor.Radio = styled(Radio)`
  && {
    height: 3rem;
    line-height: 3rem;
    display: block;
    font-size: 15px;
  }
`

Constructor.ListRadioGroup = styled(Radio.Group)`
  && {
    width: 100%;
    margin-bottom: 1rem;
  }
`

Constructor.Checkbox = styled(Checkbox)`
  && {
    height: 3rem;
    line-height: 3rem;
    display: block;
    margin-right: 0.8rem;
  }
`

Constructor.ListCheckboxGroup = styled(Checkbox.Group)`
  && {
    width: 100%;
    margin-bottom: 1rem;
  }
`

Constructor.ListSorting = styled.div`
  margin-bottom: 1rem;
`

Constructor.ListMatrix = styled.div`
  margin-bottom: 1rem;
`

Constructor.Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  // background: #f5f5f5;

  ${props =>
    props.wrap &&
    css`
      flex-wrap: wrap;
      justify-content: flex-end;
    `}
`

Constructor.Add = styled.div`
  font-weight: bold;
  cursor: pointer;
  color: #246cf6;
  margin-bottom: 2rem;

  &:hover {
    text-decoration: underline;
  }
`

Constructor.FormItem = styled(Form.Item)`
  && {
    width: 100%;
    margin: 0 1.5rem 0 0.5rem;

    input {
      border-radius: 5px;
    }
  }
`

Constructor.Item.Remove = styled.a`
  color: rgba(0, 0, 0, 0.45);
  font-size: 2.1rem;

  &:hover {
    color: ${colors.darkGreyOne};
  }
`

Constructor.Submit = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

Constructor.Dropzone = styled.div`
  height: 7rem;
  border: 1px dashed ${colors.darkGreyTwo};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1.4rem 0;
  transition: border-color 0.2s;

  &:hover {
    border-color: ${colors.blue};
  }

  ${props =>
    props.active &&
    css`
      background: rgba(0, 104, 255, 0.1);
      border-color: ${colors.blue};
    `}

  ${props =>
    props.background &&
    css`
      height: 25rem;
      background: url(${props.background}) no-repeat center;
      background-size: cover;
    `}
`

Constructor.Dropzone.Text = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
`

Constructor.Dropzone.Button = styled.div`
  border-radius: 5rem;
  border: 1px solid ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  padding: 0.4rem 2.4rem;
  text-decoration: uppercase;
  transition: border-color 0.2s, color 0.2s, opacity 0.2s;

  i {
    margin-right: 0.8rem;
  }

  ${Constructor.Dropzone}:hover & {
    border-color: ${colors.blue};
    color: ${colors.blue};

    ${props =>
      props.hide &&
      css`
        background: rgba(255, 255, 255, 0.3);
        border-color: ${colors.white};
        color: ${colors.white};
        opacity: 1;
      `}
  }

  ${props =>
    props.hide &&
    css`
      opacity: 0;
      border-color: ${colors.white};
      color: ${colors.white};
    `}
`

export { Constructor }
