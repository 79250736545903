import React from 'react'
import { Spin } from 'antd'

import { Loader } from './styled'
import { withTranslation } from 'react-i18next'

const QuizLoader = ({ t }) => {
  return (
    <Loader>
      <Spin tip={t('general:loading')}/>
    </Loader>
  )
}

export default withTranslation()(QuizLoader)
