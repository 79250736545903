import React, { Fragment } from 'react'
import { Button, Row, Col, Dropdown, Menu } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CustomRangePicker, ReportHeaderSelect, ReportSelect } from '../styled'
import NewHeader from '../../../components/NewHeader'

import CourseIcon from '../../../assets/course_report_icon.png'
import EventIcon from '../../../assets/event_report_icon.png'
import MazeIcon from '../../../assets/maze_report_icon.png'
import SkillJourneyIcon from '../../../assets/skilljourney_report_icon.png'
import ShareSphereIcon from '../../../assets/sharesphere_report_icon.png'
import LiveAssessmentsIcon from '../../../assets/live_assessments_report_icon.png'

const { Option } = ReportSelect
const dateFormat = 'YYYY-MM-DD'

// Styled Components
const StyledMenu = styled(Menu)`
  padding: 8px;
  min-width: 220px;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-dropdown-menu-item {
    padding: 12px 16px;
    border-radius: 6px;
    margin: 4px 0;
    color: #333;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 12px;

    &:hover {
      background: #f5f5f5;
    }

    &:active {
      background: #e6f7ff;
    }

    .menu-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const DownloadButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .download-header-report {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    background: #ffffff6b;
    .anticon {
      font-size: 16px;
    }
  }

  .ant-dropdown {
    animation: slideIn 0.2s ease-out;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .download-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #1890ff; // Blue color
    transition: width 0.3s ease;
    z-index: 0;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
  }
`

function ReportHeader(props) {
  const {
    t,
    dateChange,
    workshopDateType = '5',
    download,
    handleDate,
    dateFrom,
    dateTo,
    isDateActive,
    downloading,
    isSpecificItem,
    progress = 0
  } = props

  const showDateFiter = workshopDateType === '2'

  const handleMenuClick = ({ key }) => {
    download(key)
  }

  const menuItems = [
    { key: 'course', icon: CourseIcon, label: t('reports:course') },
    { key: 'maze', icon: MazeIcon, label: t('v4:maze') },
    { key: 'event', icon: EventIcon, label: t('reports:live_event') },
    {
      key: 'live_assessment',
      icon: LiveAssessmentsIcon,
      label: t('reports:live_assessment')
    },
    { key: 'quiz', icon: ShareSphereIcon, label: t('reports:quiz') },
    { key: 'trivia', icon: ShareSphereIcon, label: t('v3:share_sphere') },
    { key: 'skill', icon: SkillJourneyIcon, label: t('v4:skill_journey') }
  ]

  const menu = (
    <StyledMenu onClick={handleMenuClick}>
      {menuItems.map(({ key, icon, label }) => (
        <Menu.Item key={key}>
          <span className="menu-icon">
            <img alt="" src={icon} />
          </span>
          {label}
        </Menu.Item>
      ))}
    </StyledMenu>
  )

  const DownloadButton = () => (
    <DownloadButtonWrapper>
      <div className="download-progress" style={{ width: `${progress}%` }} />
      {isSpecificItem ? (
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
          overlayStyle={{
            minWidth: '220px',
            padding: '4px'
          }}
        >
          <Button
            icon="download"
            className="download-header-report"
            loading={downloading}
          >
            {t('reports:download')}
          </Button>
        </Dropdown>
      ) : (
        <Button
          icon="download"
          className="download-header-report"
          onClick={download}
          loading={downloading}
        >
          {t('reports:download')}
        </Button>
      )}
    </DownloadButtonWrapper>
  )

  // Rest of the component remains the same...
  return (
    <Fragment>
      <Row className="nav-report">
        {!isDateActive ? (
          <div className="shl-nav-report-date">
            <Col span={2}>{t('v4:date')}</Col>

            <Col span={4}>
              <div id="report-header-time" className="relative">
                <ReportHeaderSelect
                  value={workshopDateType}
                  onChange={dateChange}
                  dropdownStyle={{ borderRadius: '10px' }}
                  getPopupContainer={() =>
                    document.getElementById('report-header-time')
                  }
                >
                  <Option value="5" className="select-header-option">
                    {t('reports:all_time')}
                  </Option>
                  <Option value="1" className="select-header-option">
                    {t('reports:today')}
                  </Option>
                  <Option value="7" className="select-header-option">
                    {t('reports:seven_days')}
                  </Option>
                  <Option value="30" className="select-header-option">
                    {t('reports:this_month')}
                  </Option>
                  <Option value="12" className="select-header-option">
                    {t('reports:one_year')}
                  </Option>
                  <Option value="2" className="select-header-option">
                    {t('v4:other')}
                  </Option>
                </ReportHeaderSelect>
              </div>
            </Col>
            {showDateFiter && (
              <Col span={4}>
                <div id="report-header-range" className="relative">
                  <CustomRangePicker
                    format={dateFormat}
                    onChange={handleDate}
                    value={
                      dateFrom && dateTo
                        ? [moment(dateFrom), moment(dateTo)]
                        : null
                    }
                    separator="-"
                  />
                </div>
              </Col>
            )}
          </div>
        ) : (
          ''
        )}
        <Col span={5} style={{ float: 'right' }}>
          <DownloadButton />
        </Col>
        <Col span={9}>
          <div className="shl-nav-report-head">
            <NewHeader />
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default withTranslation(['report', 'v4', 'v3', 'common'])(ReportHeader)
