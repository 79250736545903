import http, { httpShareBotInstance, questionAnswerAiInstance } from './http'

function generateImage(data) {
  return http({
    method: 'POST',
    url: '/midjourney/imagine',
    data
  })
}
function generateVideo(data) {
  return http({
    method: 'POST',
    url: '/midjourney/create',
    data
  })
}

function generateVariation(data) {
  return http({
    method: 'POST',
    url: '/midjourney/variation',
    data
  })
}

function upscaleImage(data) {
  return http({
    method: 'POST',
    url: '/midjourney/upscale',
    data
  })
}
function uploadMidjourneyImage(data) {
  return http({
    method: 'POST',
    url: '/midjourney/upload',
    data
  })
}

function conversationAI(data) {
  return httpShareBotInstance({
    method: 'POST',
    url: '/conversationalai',
    data
  })
}

function generateIdeogramImage(data) {
  return questionAnswerAiInstance({
    method: 'POST',
    url: '/?service=ideogram',
    data
  })
}

export {
  generateImage,
  upscaleImage,
  generateVariation,
  conversationAI,
  generateIdeogramImage
}
