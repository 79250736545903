import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../../../store/actions'
import QuizQuestions from './QuizQuestions'

import * as quizesActions from '../../../../../../store/actions/quizes'

export default connect(
  state => ({
    user: state.user,
    quizes: state.quizes
  }),
  dispatch => ({
    quizesActions: bindActionCreators(quizesActions, dispatch),
    upload: (file, opts) => dispatch(actions.library.upload(file, opts))
  })
)(QuizQuestions)
