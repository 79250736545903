import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import update from 'react-addons-update'
import uuidv4 from 'uuid/v4'
import _ from 'lodash'
import {
  Form,
  Button,
  Input,
  Icon,
  Spin,
  message,
  Tooltip,
  InputNumber,
  Select,
  Row,
  Col
} from 'antd'

import { Constructor } from './styled'
import { withTranslation } from 'react-i18next'
import nanoid from 'nanoid'

import DeleteIcon from '../../../../../../assets/bin.svg'
class QuizQuestions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      single: [],
      multiple: [],
      sorting: [],
      matrix: [],
      free: null,
      type: 'single',
      types: ['single', 'multiple', 'sorting', 'matrix'],
      correctRadio: null,
      correctChecked: [],
      fetchUpload: false,
      idUpload: null,
      isQuizAi: false
    }
  }

  componentDidMount() {
    const { data } = this.props

    if (data) {
      this.handleTypeOnStart(data)
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props

    if (data && data !== prevProps.data) {
      this.handleTypeOnStart(data)
    }
  }

  setData = data => {
    this.setState({
      type: data.type,
      correctRadio: this.onCheckCorrectSingle(),
      correctChecked: this.onCheckCorrectMultiple()
    })
  }

  handleTypeOnStart = data => {
    this.setState(
      {
        [data.type]: this.onCheckState(data, data.type)
      },
      () => {
        this.setData(data)
      }
    )
  }

  onCheckState = (data, type) => {
    return data ? (data.type === type ? data.answers : []) : []
  }

  onCheckCorrectSingle = () => {
    const { single } = this.state

    return _.findIndex(single, ['correct', true])
  }

  onCheckCorrectMultiple = () => {
    const { multiple } = this.state

    return _.map(_.keys(_.pickBy(multiple, { correct: true })), Number)
  }

  emptyAnswer = (t, prevState, obj = { answer: '', correct: false }) => {
    const { type } = this.state

    return {
      [t]:
        type === t
          ? [
              ...prevState[t],
              {
                uid: uuidv4()
                  .split('-')
                  .pop(),
                ...obj
              }
            ]
          : prevState[t]
    }
  }

  onAddAnswer = () => {
    this.setState(prevState => ({
      ...this.emptyAnswer('single', prevState),
      ...this.emptyAnswer('multiple', prevState),
      ...this.emptyAnswer('sorting', prevState, {
        answer: ''
      }),
      ...this.emptyAnswer('matrix', prevState, {
        criterion: '',
        elements: '',
        content: '',
        content_id: null
      })
    }))
  }

  onRemoveAnswer = id => {
    const { type, types } = this.state

    this.setState({
      correctChecked: this.state.correctChecked.filter(answer => answer !== id)
    })

    this.setState(prevState => {
      return Object.assign(
        ...types.map((item, index) => ({
          [item]:
            type === item
              ? prevState[item].filter((_, i) => i !== id)
              : prevState[item]
        }))
      )
    })
  }

  onChangeFreeAnswer = e => {
    this.setState({
      free: e.target.value
    })
  }

  onChangeAnswer = (e, id) => {
    e.persist()

    const { type, types } = this.state

    const objProps = (prop, obj) =>
      update(prop, {
        [id]: { [obj]: { $set: e.target.value } }
      })

    this.setState(prevState => {
      return Object.assign(
        ...types.map((item, i) =>
          item !== 'matrix'
            ? {
                [item]:
                  type === item
                    ? objProps(prevState[item], 'answer')
                    : prevState[item]
              }
            : {
                [item]:
                  type === item
                    ? e.target.attributes.getNamedItem('data-name').value ===
                      'criterion'
                      ? objProps(prevState[item], 'criterion')
                      : objProps(prevState[item], 'elements')
                    : prevState[item]
              }
        )
      )
    })
  }

  onChangeRadio = e => {
    const id = e.target.value
    const { single } = this.state

    single.map((item, i) => (item.correct = false))

    this.setState({
      single: update(single, {
        [id]: { correct: { $set: true } }
      }),
      correctRadio: e.target.value
    })
  }

  handleChange = (e, i) => {
    this.setState(({ multiple }) => ({
      multiple: multiple.map((item, index) => {
        if (index === i) {
          item.correct = e.target.checked
        }
        return item
      })
    }))

    e.target.checked
      ? this.setState({
          correctChecked: [...this.state.correctChecked, i]
        })
      : this.setState({
          correctChecked: this.state.correctChecked.filter(index => index !== i)
        })
  }

  handleDrop = (dropped, id) => {
    const { upload } = this.props
    const { matrix } = this.state
    const file = dropped[0]

    this.setState({
      fetchUpload: true,
      idUpload: id
    })

    return upload(file)
      .then(res => {
        matrix[id].content = res.link
        matrix[id].content_id = res.id

        this.setState({
          matrix,
          fetchUpload: false
        })
      })
      .catch(err => {
        this.setState({
          fetchUpload: false
        })

        throw err
      })
  }

  onChangeAnswerType = value => {
    this.setState({
      type: value
    })
  }

  handleSubmitAiQuiz = data => {
    this.setState(
      {
        type: 'single',
        single: [
          {
            uid: uuidv4()
              .split('-')
              .pop(),
            answer: data.answer,
            correct: true
          }
        ]
      },
      () => {
        this.handleSubmit(data)
      }
    )
  }

  handleSubmit = param => {
    const {
      form,
      onCancel,
      quizesActions,
      data,
      updateCurrentQuizQuestions,
      currentQuiz,
      handleAddNewQuizElement,
      isAiCreation
    } = this.props

    const {
      single,
      multiple,
      sorting,
      matrix,
      type,
      correctRadio,
      correctChecked
    } = this.state

    const singleError = type === 'single' && correctRadio === null
    const multipleError = type === 'multiple' && correctChecked.length === 0

    const questions = currentQuiz.questions || []
    const id = currentQuiz.id

    form.validateFields((err, values) => {
      const { t } = this.props

      if (singleError || multipleError) {
        message.error(t('errors:answer'))
        return
      }

      if (!err) {
        const update = (t, arr = []) => {
          const obj =
            t === 'free'
              ? {
                  uid: uuidv4()
                    .split('-')
                    .pop(),
                  type: t,
                  ...values
                }
              : {
                  uid: uuidv4()
                    .split('-')
                    .pop(),
                  type: t,
                  ...values,
                  answers: arr
                }

          if (data) {
            questions[isAiCreation ? data.uid : data.id] = obj
          }

          if (isAiCreation) {
            updateCurrentQuizQuestions(questions)
          } else {
            quizesActions.updateQuestions(
              {
                questions: data ? questions : [...questions, obj]
              },
              id
            )
            updateCurrentQuizQuestions(data ? questions : [...questions, obj])
          }

          if (data === null && questions.length === 0) {
            const uid = nanoid(8)
            handleAddNewQuizElement({
              uId: uid,
              data: { content: id, questions_count: 0 }
            })
          }
        }

        if (onCancel) {
          onCancel()
        }

        switch (type) {
          case 'single':
            return update('single', single)
          case 'multiple':
            return update('multiple', multiple)
          case 'free':
            return update('free')
          case 'sorting':
            return update('sorting', sorting)
          case 'matrix':
            return update('matrix', matrix)
          default:
            return {}
        }
      }
    })
  }

  onChangePoint = param => {
    console.log('points', param)
  }

  handleQuizAi = () =>
    this.setState({
      isQuizAi: true
    })

  render() {
    const {
      form,
      form: { getFieldDecorator },
      data,
      currentQuiz,
      t,
      user: {
        info: { active_organization_id }
      }
    } = this.props

    const {
      single,
      multiple,
      sorting,
      matrix,
      type,
      fetchUpload,
      idUpload,
      isQuizAi,
      isPointValid
    } = this.state

    const titlePoints =
      'Make sure quiz points do not exceed 100 for specific course'

    console.log('questions state', this.state)

    return (
      <Constructor>
        <Form
          onSubmit={e => {
            e.preventDefault()
            this.handleSubmit()
          }}
        >
          <Constructor.Header>
            <Constructor.Name>
              <Constructor.BoldName>
                {t('labels:quiz_name')}:{' '}
              </Constructor.BoldName>
              {currentQuiz && currentQuiz.title}
            </Constructor.Name>
          </Constructor.Header>

          {!isQuizAi && (
            <>
              {' '}
              <Form.Item>
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: t('errors:question')
                    }
                  ],
                  initialValue: data && data.title
                })(
                  <Input.TextArea
                    rows={4}
                    placeholder={t('placeholders:question')}
                  />
                )}
              </Form.Item>
              <Row>
                <Col md={12}>
                  <Form.Item
                    name="point"
                    label="Points"
                    rules={[{ required: true }]}
                  >
                    <Tooltip
                      trigger={['focus']}
                      title={titlePoints}
                      placement="topLeft"
                      overlayClassName="numeric-input"
                    >
                      {getFieldDecorator('point', {
                        initialValue:
                          data && data.point !== undefined ? data.point : 10
                      })(
                        <InputNumber
                          size="large"
                          min={0}
                          max={100}
                          onChange={this.onChangePoint}
                          style={{ width: '90%' }}
                        />
                      )}
                    </Tooltip>
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    name="answer_type"
                    label={t('labels:answer_type')}
                    rules={[{ required: false }]}
                  >
                    {/* <Constructor.Title>
                    {t('labels:answer_type')}
                  </Constructor.Title> */}
                    {getFieldDecorator('answer_type', {
                      initialValue: type
                    })(
                      <Select onChange={this.onChangeAnswerType}>
                        <Select.Option value="single">
                          {t('labels:choice_single')}
                        </Select.Option>
                        <Select.Option value="multiple">
                          {t('labels:choice_multiple')}
                        </Select.Option>
                        <Select.Option value="free">
                          {t('labels:choice_free')}
                        </Select.Option>
                        <Select.Option value="sorting">
                          {t('labels:choice_sorting')}
                        </Select.Option>
                        <Select.Option value="matrix">
                          {t('labels:choice_matrix')}
                        </Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {type && (
                <Constructor.Title>{t('labels:answers')}</Constructor.Title>
              )}
              {type === 'single' && (
                <Constructor.ListRadioGroup
                  onChange={this.onChangeRadio}
                  value={this.onCheckCorrectSingle()}
                >
                  {single.map((item, i) => (
                    <Constructor.Item key={i}>
                      <Constructor.Radio value={i} />

                      <Constructor.FormItem>
                        {getFieldDecorator(`${i}`, {
                          rules: [
                            {
                              required: true,
                              message: t('errors:answer')
                            }
                          ],
                          initialValue: item.answer
                        })(
                          <Input
                            type="text"
                            size="large"
                            placeholder={t('placeholders:answer')}
                            onChange={e => this.onChangeAnswer(e, i)}
                          />
                        )}
                      </Constructor.FormItem>
                      <Constructor.Item.Remove
                        onClick={() => this.onRemoveAnswer(i)}
                      >
                        <Icon type="delete" style={{ color: '#FF0000' }} />
                      </Constructor.Item.Remove>
                    </Constructor.Item>
                  ))}
                </Constructor.ListRadioGroup>
              )}
              {type === 'multiple' && (
                <Constructor.ListCheckboxGroup
                  value={this.onCheckCorrectMultiple()}
                >
                  {multiple.map((item, i) => (
                    <Constructor.Item key={i}>
                      <Constructor.Checkbox
                        value={i}
                        onChange={e => this.handleChange(e, i)}
                      />

                      <Constructor.FormItem>
                        {getFieldDecorator(`${i}`, {
                          rules: [
                            {
                              required: true,
                              message: t('errors:answer')
                            }
                          ],
                          initialValue: item.answer
                        })(
                          <Input
                            type="text"
                            size="large"
                            placeholder={t('placeholders:answer')}
                            onChange={e => this.onChangeAnswer(e, i)}
                          />
                        )}
                      </Constructor.FormItem>

                      <Constructor.Item.Remove
                        onClick={() => this.onRemoveAnswer(i)}
                      >
                        <Icon type="delete" style={{ color: '#FF0000' }} />
                      </Constructor.Item.Remove>
                    </Constructor.Item>
                  ))}
                </Constructor.ListCheckboxGroup>
              )}
              {type === 'free' && (
                <Form.Item>
                  {getFieldDecorator('free', {
                    rules: [
                      {
                        required: true,
                        message: t('errors:answer')
                      }
                    ],
                    initialValue: data && data.free
                  })(
                    <Input.TextArea
                      rows={4}
                      onChange={e => this.onChangeFreeAnswer(e)}
                      placeholder={t('placeholders:answer')}
                    />
                  )}
                </Form.Item>
              )}
              {type === 'sorting' && (
                <Constructor.ListSorting>
                  {sorting.map((item, i) => (
                    <Constructor.Item key={i}>
                      <Constructor.FormItem>
                        {getFieldDecorator(`${i}`, {
                          rules: [
                            {
                              required: true,
                              message: t('errors:answer')
                            }
                          ],
                          initialValue: item.answer
                        })(
                          <Input
                            type="text"
                            size="large"
                            placeholder={t('placeholders:answer')}
                            onChange={e => this.onChangeAnswer(e, i)}
                          />
                        )}
                      </Constructor.FormItem>

                      <Constructor.Item.Remove
                        onClick={() => this.onRemoveAnswer(i)}
                      >
                        <Icon type="delete" style={{ color: '#FF0000' }} />
                      </Constructor.Item.Remove>
                    </Constructor.Item>
                  ))}
                </Constructor.ListSorting>
              )}
              {type === 'matrix' && (
                <Constructor.ListMatrix>
                  {matrix.map((item, i) => (
                    <Constructor.Item key={i} wrap="true">
                      <Constructor.FormItem>
                        {getFieldDecorator(`criterion${i}`, {
                          rules: [
                            {
                              required: true,
                              message: t('errors:criterion')
                            }
                          ],
                          initialValue: item.criterion
                        })(
                          <Input.TextArea
                            data-name="criterion"
                            onChange={e => this.onChangeAnswer(e, i)}
                            placeholder={t('placeholders:criterion')}
                            rows={3}
                          />
                        )}
                      </Constructor.FormItem>

                      <Spin spinning={idUpload === i ? fetchUpload : false}>
                        <Dropzone
                          accept="image/*"
                          onDrop={v => this.handleDrop(v, i)}
                          style={{ width: '100%' }}
                        >
                          {({ isDragActive }) => (
                            <Constructor.Dropzone
                              active={isDragActive}
                              background={item.content ? item.content : ''}
                            >
                              {isDragActive ? (
                                <Constructor.Dropzone.Text>
                                  {t('labels:drop_file')}
                                </Constructor.Dropzone.Text>
                              ) : (
                                <Constructor.Dropzone.Button hide={false}>
                                  <Icon type="picture" />{' '}
                                  {t('buttons:upload_image')}
                                </Constructor.Dropzone.Button>
                              )}
                            </Constructor.Dropzone>
                          )}
                        </Dropzone>
                      </Spin>

                      <Constructor.FormItem>
                        {getFieldDecorator(`elements${i}`, {
                          rules: [
                            {
                              required: true,
                              message: t('errors:element')
                            }
                          ],
                          initialValue: item.elements
                        })(
                          <Input.TextArea
                            data-name="elements"
                            onChange={e => this.onChangeAnswer(e, i)}
                            placeholder={t('placeholders:elements')}
                            rows={3}
                          />
                        )}
                      </Constructor.FormItem>

                      <Constructor.Item.Remove
                        onClick={() => this.onRemoveAnswer(i)}
                      >
                        <img src={DeleteIcon} alt="delete" />
                      </Constructor.Item.Remove>
                    </Constructor.Item>
                  ))}
                </Constructor.ListMatrix>
              )}
              {type && type !== 'free' && (
                <Constructor.Add onClick={() => this.onAddAnswer()}>
                  + {t('buttons:add_answer')}
                </Constructor.Add>
              )}
              <Constructor.Submit>
                <Button
                  className="shl-primary-btn"
                  htmlType="submit"
                  shape="round"
                  size="large"
                  style={{ width: '100%' }}
                  disabled={
                    !(this.state[type] && this.state[type].length !== 0)
                  }
                >
                  {data ? t('buttons:update') : t('buttons:save')}
                </Button>
              </Constructor.Submit>
            </>
          )}
        </Form>
      </Constructor>
    )
  }
}

export default Form.create()(withTranslation()(QuizQuestions))
