import styled, { css } from 'styled-components'
import Dropzone from 'react-dropzone'
import { Row } from 'antd'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const MediaDrop = styled(Dropzone)`
  border: 1px solid #cccccc;
  padding: 0.5rem 1rem;
`

const Quiz = styled.div`
  margin: 12px 0;
  display: flex;
`

Quiz.Main = styled.div`
  flex-direction: column;
  width: 540px;
`

Quiz.Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${colors.darkGreyThree};
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 3rem;
  }
`

Quiz.Create = styled.a`
  width: 100%;
  border: 0.1rem solid #cccccc;
  color: ${colors.darkGreyOne};
  padding: 0.5rem 1rem;
  display: inline-block;

  &:hover {
    color: ${colors.darkGreyOne};
  }
`

Quiz.Add = styled(Row)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 2px dashed ${colors.blue};
  height: 5rem !important;
  margin: 2rem 0;
  border-radius: 5px;
  width: 100%;
  color: ${colors.blue};
  cursor: pointer;

  &:hover {
    background: #fdfdfd;
    border: 2px dotted ${colors.blue};
  }
`

Quiz.Back = styled.a`
  width: 100%;
  display: flex;

  &:hover {
    text-decoration: underline !important;
  }
`

Quiz.Back.Label = styled.div`
  line-height: 1;
  margin-left: 0.5rem;
`

Quiz.Title = styled.div`
  color: ${colors.black};
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin: 0 2.5rem 2.5rem 2.5rem;
`
Quiz.Info = styled.span`
  color: #5d5d5d;
  margin-bottom: 1rem;
`

Quiz.Name = styled.span`
  font-weight: 600;
  color: ${colors.black};
`

Quiz.Empty = styled.div`
  padding: 2rem 0;
  text-align: center;
`

Quiz.List = styled.div`
  max-height: 27rem;
  overflow-y: auto;
`

Quiz.Item = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  border: ${props =>
    props.active ? '2px solid #246CF6' : '1px solid #c9c9c9'};
  position: relative;
  padding-left: 1rem;
  border-radius: 10px;

  ${props =>
    props.active &&
    css`
      &:before {
        content: '';
        width: 0.4rem;
        height: 100%;
        background-color: ${colors.blue};
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    `}
`

Quiz.Item.Name = styled.div`
  color: ${colors.black};
  text-align: justify;
`

Quiz.Item.Name.Link = styled.a`
  font-weight: 600;
`

Quiz.Item.Number = styled.span`
  display: inline-block;
  font-weight: 700;
`

Quiz.Item.Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
  gap: 1rem;
`

Quiz.Item.Events = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

Quiz.Item.Event = styled.div`
  margin-left: 1rem;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus,
  &:active {
    border-radius: 50%;
    background: #efefef;
  }

  ${props =>
    props.active &&
    css`
      border-radius: 50%;
      background: #efefef;
    `}
`

Quiz.Label = styled.span`
  font-size: 15px;
  color: #246cf6;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export { MediaDrop, Quiz }
