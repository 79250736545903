import React, { Fragment, useState } from 'react'
import {
  Button,
  Col,
  Empty,
  Popconfirm,
  Progress,
  Row,
  Tooltip,
  message
} from 'antd'
import {
  PlayCircleFilled,
  PauseCircleOutlined,
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  UploadOutlined,
  OpenAIOutlined,
  DownloadOutlined
} from '@ant-design/icons'
import './AudioList.scss'
import axios from 'axios'
import { api } from '../../../../../services'
import fileSaver from 'file-saver'
import AudioPlayer from './AudioPlayer'
import { Container, Draggable } from 'react-smooth-dnd'
import UploadAudioModal from '../UploadAudioModal/UploadAudioModal'
import DiscussionCreateMachine from '../../DiscussionCreatetMachine/DiscussionCreateMachine'
const AudioList = ({
  t,
  roomName,
  description,
  selectedCover,
  isSuperAdmin,
  isAdmin,
  isPublishing,
  isSaving,
  audioUrl,
  isCreator,
  podcastId,
  dataList,
  isDownLoading,
  handleSelectAudio,
  isUploading,
  changeUploadingStatus,
  setLocalAudio,
  setMultipleLocal,
  removeLocalAudio,
  onDragDrop,
  library,
  upload,
  clearUploadList,
  changePodcastDescription
}) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [aiAudio, openAiAudio] = useState(false)
  const [uploadAudio, openUploadAudio] = useState({
    open: false,
    type: 'ADD'
  })
  const [isDeleting, setIsDeleting] = useState({ status: false, id: '' })
  const [isPlaying, setIsPlating] = useState(false)
  const [playPause, setPlayPause] = useState({ status: false, type: '' })
  const [audioData, setAudioData] = useState({
    id: '',
    cover: null,
    description: '',
    title: '',
    filename: '',
    format: 'AUDIO',
    audio_link: '',
    key: '',
    type: 'audio/mpeg'
  })
  const [uploadProgress, setUploadProgress] = useState(0)
  const changeFile = async (files, originalData) => {
    let file = files[0].originFileObj ? files[0].originFileObj : false
    if (file) {
      if (file.type !== 'audio/mpeg') {
        message.error(t('v4:audio_modal.msg_valid_file'))
        return
      }
      let fileData = {
        filename: file.name,
        type: file.type
      }
      changeUploadingStatus({ status: true, type: 'custom' })
      api.library
        .sign(fileData)
        .then(async ({ data }) => {
          setUploadProgress(0)
          let opts = {
            headers: {
              'Content-Type': 'audio/mpeg'
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              const percentCompleted = Math.round((loaded * 100) / total)
              setUploadProgress(percentCompleted)
            }
          }
          try {
            let res = await axios.put(data.link, file, opts)
            if (res.status < 300) {
              let mediaData = {
                event_id: parseInt(podcastId),
                id: audioData.id,
                filename: fileData.filename,
                title: originalData.title,
                description: originalData.description,
                cover_id: originalData.cover_id,
                format: 'AUDIO',
                key: data.key,
                type: 'audio/mpeg'
              }
              await api.library
                .upload(mediaData)
                .then(async ({ data }) => {
                  if (data) {
                    setLocalAudio(data, audioData.id)
                    audioData.id &&
                      (await api.library.removeMedias({
                        mediaIds: [audioData.id]
                      }))
                  }
                  setTimeout(() => {
                    changeUploadingStatus({ status: false, type: 'custom' })
                    closeAudioModal()
                    message.success(t('v4:audio_modal.msg_success'))
                  }, 500)
                })
                .catch(err => {
                  changeUploadingStatus({ status: false, type: 'custom' })
                  message.error(t('v4:audio_modal.msg_failed'))
                })
            } else {
              throw new Error(t('v4:audio_modal.msg_failed'))
            }
          } catch (error) {
            changeUploadingStatus({ status: false, type: 'custom' })
            message.error(t('v4:audio_modal.msg_error'))
          }
        })
        .catch(err => {
          changeUploadingStatus({ status: false, type: 'custom' })
          message.error(t('v4:audio_modal.msg_failed'))
        })
    } else {
      changeUploadingStatus({ status: true, type: 'custom' })
      let mediaData = {
        event_id: parseInt(podcastId),
        id: audioData.id,
        title: originalData.title,
        description: originalData.description,
        cover_id: originalData.cover_id
      }
      await api.library
        .updateAR(mediaData, audioData.id)
        .then(async ({ data }) => {
          if (data) {
            setLocalAudio(data, audioData.id)
            // audioData.id &&
            //   (await api.library.removeMedias({
            //     mediaIds: [audioData.id]
            //   }))
          }
          setTimeout(() => {
            changeUploadingStatus({ status: false, type: 'custom' })
            closeAudioModal()
            message.success(t('v4:audio_modal.msg_success'))
          }, 500)
        })
        .catch(err => {
          changeUploadingStatus({ status: false, type: 'custom' })
          message.error(t('v4:audio_modal.msg_error'))
        })
    }
  }
  const generateAudio = async allEpisode => {
    try {
      changeUploadingStatus({ status: true, type: 'machine' })
      openAiAudio(false)
      let allDataList = []
      const uploadPromises = allEpisode.map(async audioInfo => {
        const mediaData = {
          event_id: parseInt(podcastId),
          id: audioInfo.id,
          title: audioInfo.title,
          description: audioInfo.description,
          cover_id: audioInfo.cover_id
        }
        const { data } = await api.library.updateAR(mediaData, audioInfo.id)
        allDataList.push(data)
      })
      await Promise.all(uploadPromises)
      setMultipleLocal(allDataList)
    } catch (err) {
      changeUploadingStatus({ status: false, type: 'machine' })
      message.error(t('v4:audio_modal.msg_failed'))
    }
  }

  const downloadFile = () => {
    setIsDownloading(true)
    fileSaver.saveAs(audioUrl.url, audioUrl.filename)
    setTimeout(() => {
      setIsDownloading(false)
    }, 700)
  }
  const changeAudioStatus = status => {
    setIsPlating(status)
  }
  const deleteAudio = async audio => {
    setIsDeleting({ status: true, id: audio.id })
    await api.library.removeMedias({
      mediaIds: [audio.id]
    })
    removeLocalAudio(audio.id)
    setIsDeleting({ status: false, id: '' })
  }
  const onUploadSubmit = data => {
    changeFile(data.files, data)
  }
  const handleClickUpload = audio => {
    if (audio) {
      setAudioData({
        ...audioData,
        id: audio.id,
        cover: audio.cover,
        filename: audio.filename,
        description: audio.description,
        title: audio.title,
        audio_link: audio.link,
        filename: audio.filename,
        title: audio.title
      })
    }
    openUploadAudio({ open: true, type: audio ? 'EDIT' : 'ADD' })
  }
  const closeAudioModal = () => {
    setAudioData({
      id: '',
      cover: null,
      description: '',
      title: '',
      filename: '',
      format: 'AUDIO',
      audio_link: '',
      key: '',
      type: 'audio/mpeg'
    })
    openUploadAudio({ ...uploadAudio, open: false })
  }
  return (
    <div className="podcast-list">
      {aiAudio && (
        <DiscussionCreateMachine
          library={library}
          upload={upload}
          roomName={roomName}
          description={description}
          selectedCover={selectedCover}
          key={'DiscussionCreateMachine'}
          open={aiAudio}
          close={() => {
            openAiAudio(false)
          }}
          clearUploadList={clearUploadList}
          onFinish={generateAudio}
          changePodcastDescription={changePodcastDescription}
        />
      )}
      {uploadAudio.open && (
        <UploadAudioModal
          key={'UploadAudioModal'}
          t={t}
          isUploading={isUploading.status}
          audioData={audioData}
          type={uploadAudio.type}
          open={uploadAudio.open}
          close={closeAudioModal}
          onFinish={onUploadSubmit}
        />
      )}
      <div className="podcast-list_header">
        <div className="podcast-list_header_title">
          {t('v4:podcastTitle')}{' '}
          <Tooltip placement="top" title={t('v4:podcastToolTipTitle')} arrow>
            <InfoCircleOutlined style={{ color: '#5057D5' }} />
          </Tooltip>
        </div>
        {isCreator && (
          <div className="podcast-list_header_btn">
            {isUploading.status && isUploading.type === 'machine' ? (
              <Button type="primary" className={`rounded btn-size`} loading>
                {t('v4:aiAudio')}
              </Button>
            ) : isUploading.status && isUploading.type === 'custom' ? (
              <Button type="primary" className={`rounded btn-size`} loading>
                {t('v4:uploadAudio')}
              </Button>
            ) : (
              <Fragment>
                <Tooltip placement="left" arrow title={t('v4:btn_ai_podcast')}>
                  <Button
                    type="primary"
                    className={`rounded btn-size`}
                    disabled={
                      isSaving || isPublishing || !podcastId
                      || (!isAdmin && !isSuperAdmin)
                    }
                    onClick={e => {
                      e.preventDefault()
                      openAiAudio(true)
                    }}
                  >
                    <OpenAIOutlined />
                    {t('v4:aiAudio')}
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="left"
                  arrow
                  title={t('v4:btn_upload_podcast')}
                >
                  <Button
                    type="primary"
                    className={`rounded btn-size`}
                    disabled={isSaving || isPublishing || !podcastId}
                    onClick={e => {
                      e.preventDefault()
                      handleClickUpload()
                    }}
                  >
                    <UploadOutlined />
                    {t('v4:uploadAudio')}
                  </Button>
                </Tooltip>
              </Fragment>
            )}
          </div>
        )}
      </div>
      {dataList.length === 0 && (
        <Empty
          description={
            podcastId ? t('v4:emptyPodcast') : t('v4:emptyCreatePodcast')
          }
        />
      )}
      <div className="podcast-list_content">
        <Container groupName="1" onDrop={onDragDrop}>
          {dataList.map((audio, i) => {
            return (
              <Draggable key={i} payload={audio}>
                <div
                  className={`podcast-list_content_container ${isDownLoading.id ===
                    audio.id && 'podcast-list_content_active'}`}
                  key={i}
                >
                  <Row justify="space-between" align="middle" gutter={[8, 8]}>
                    <Col
                      span={isCreator ? 16 : 18}
                      style={{ textAlign: 'left' }}
                    >
                      <div className="podcast-list_content_name">
                        {audio.title}
                      </div>
                      <div className="podcast-list_content_time">
                        {audio.duration} mins
                      </div>
                    </Col>
                    <Col
                      span={isCreator ? 2 : 4}
                      style={{ textAlign: 'right' }}
                      onClick={e => {
                        e.preventDefault()
                        if (!isUploading.status && !isDeleting.status) {
                          handleSelectAudio(audio)
                        }
                      }}
                    >
                      <div className="podcast-list_content_controls">
                        {!isDownLoading.status &&
                        isDownLoading.id === audio.id ? (
                          <>
                            {!isPlaying && (
                              <Tooltip
                                placement="topLeft"
                                title={t('v4:playPodcast')}
                                arrow
                              >
                                <PlayCircleFilled
                                  onClick={() => {
                                    setPlayPause({ status: true, type: 'PLAY' })
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: '#00000054',
                                    margin: '5px'
                                  }}
                                />
                              </Tooltip>
                            )}
                            {isPlaying && (
                              <Tooltip
                                placement="topLeft"
                                title={t('v4:pausePodcast')}
                                arrow
                              >
                                <PauseCircleOutlined
                                  onClick={() => {
                                    setPlayPause({
                                      status: true,
                                      type: 'PAUSE'
                                    })
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: '#00000054',
                                    margin: '5px'
                                  }}
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <Tooltip
                            placement="topLeft"
                            title={t('v4:selectPodcast')}
                            arrow
                          >
                            <PlayCircleFilled
                              onClick={() => {
                                setPlayPause({ status: false, type: '' })
                              }}
                              style={{
                                fontSize: 20,
                                color: '#00000054',
                                margin: '5px'
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </Col>
                    <Col
                      span={2}
                      style={{ textAlign: 'center' }}
                      onClick={() => {
                        downloadFile()
                      }}
                    >
                      <div className="podcast-list_content_uploadbtn">
                        {!isDownloading && (
                          <Tooltip
                            placement="topLeft"
                            title={t('v4:downloadPodcast')}
                            arrow
                          >
                            <DownloadOutlined
                              style={{
                                fontSize: 20,
                                color: '#52C41A',
                                margin: '5px'
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </Col>
                    {isCreator && (
                      <Col
                        span={2}
                        style={{ textAlign: 'center' }}
                        onClick={e => {
                          e.preventDefault()
                          if (!isUploading.status && !isDeleting.status) {
                            handleClickUpload(audio)
                          }
                        }}
                      >
                        <div className="podcast-list_content_uploadbtn">
                          <Tooltip
                            placement="topLeft"
                            title={t('v4:uploadAudioFile')}
                            arrow
                          >
                            <EditOutlined
                              style={{
                                fontSize: 20,
                                color: '#1577FF',
                                margin: '5px'
                              }}
                            />
                          </Tooltip>
                        </div>
                      </Col>
                    )}
                    {isCreator && (
                      <Col span={2} style={{ textAlign: 'center' }}>
                        <Popconfirm
                          title={t('v4:deleteConfirm')}
                          onConfirm={e => {
                            e.preventDefault()
                            if (!isUploading.status && !isDeleting.status) {
                              deleteAudio(audio)
                            }
                          }}
                          okText={t('general:yes')}
                          cancelText={t('general:no')}
                          icon={false}
                        >
                          <Tooltip
                            placement="topLeft"
                            title={t('v4:btn_delete_episode')}
                            arrow
                          >
                            <div className="podcast-list_content_uploadbtn">
                              {isDeleting.status &&
                              isDeleting.id === audio.id ? (
                                <LoadingOutlined
                                  style={{
                                    fontSize: 20,
                                    color: 'red',
                                    margin: '5px'
                                  }}
                                />
                              ) : (
                                <DeleteOutlined
                                  style={{
                                    fontSize: 20,
                                    color: 'red',
                                    margin: '5px'
                                  }}
                                />
                              )}
                            </div>
                          </Tooltip>
                        </Popconfirm>
                      </Col>
                    )}
                  </Row>
                  {audioUrl.url && isDownLoading.id === audio.id && (
                    <Row align="middle" gutter={[8, 8]}>
                      <Col span={24}>
                        <AudioPlayer
                          playPause={playPause}
                          audioUrl={audioUrl.url}
                          changeAudioStatus={changeAudioStatus}
                        />
                      </Col>
                    </Row>
                  )}
                  {isUploading.status && audioData.id === audio.id && (
                    <Progress percent={uploadProgress} size="small" />
                  )}
                </div>
              </Draggable>
            )
          })}
        </Container>
      </div>
    </div>
  )
}
export default AudioList
