import React, { Component } from 'react'
import ReactDom from 'react-dom'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import ScrollContainer from 'react-indiana-drag-scroll'
import queryString from 'query-string'
import { Icon, Input, Select, Carousel, Checkbox, Tooltip, message } from 'antd'
import ReactSVG from 'react-svg'
import { TopPageLink } from '../../../../components'
import { withTranslation } from 'react-i18next'
import './Gallery.scss'
import { bytesToSize } from '../../../../utils'
import Preview from '../../components/Preview'
import { pdfImage, pptImage, textIcon } from './images'
import Spinner from '../../../../components/Spinner/Spinner'
import { builder, general } from '../../../../constants'
import { updateMedia } from '../../../../services/api/library'
import { api } from '../../../../services'
import history from '../../../../history'
import EmptyMessage from '../../../../components/EmptyMessage/EmptyMessage'
import fileSaver from 'file-saver'
import CreatableSelect from 'react-select/creatable'
import { DownloadOutlined } from '@ant-design/icons'
import { fontSize } from 'styled-system'
const { types } = builder
const { TextArea } = Input
const downloadSize = [
  { title: 'Square (150 × 150)', type: 'thumbnail' },
  { title: 'Small (300 × 300)', type: 'medium' },
  { title: 'Medium (800 × 600)', type: 'medium_large' },
  { title: 'Large (1024 × 768)', type: 'large' },
  { title: 'Original (1920× 1080)', type: 'extra_large' }
]

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.sliderContainer = React.createRef()
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.changeIndex = _.debounce(this.changeIndex, 300)
    this.changeMedia = _.debounce(this.changeMedia, 300)
    this.state = {
      nav: null,
      index: 0,
      detail: {},
      page: 1, // this variable use for my media for current page to get api
      pageSize: 20, // this variable use for my media for page size in pagination
      data: {},
      isFetching: true,
      disable: true,
      description: '',
      keywords: null,
      topics: [],
      isEditTopic: false,
      isEditKeyword: false,
      isDownload: false,
      isDownloading: false,
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      isTopicsMax: false,
      isChangeTopics: false
    }
  }

  async componentWillMount() {
    await this.getLibrary()
  }

  getLibrary = async () => {
    const {
      getLibraryList,
      location: { search: queryUri }
    } = this.props
    const { pageSize } = this.state
    const {
      page,
      isPublic,
      search,
      type,
      sort,
      isOrg,
      isActiveOrg
    } = queryString.parse(queryUri)
    await getLibraryList({
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      search,
      type: type,
      isPublic,
      isOrg,
      sort,
      isActiveOrg
    })
    const {
      data,
      data: { rows: libraries },
      publicLibrary,
      orgLibrary,
      isFetching
    } = this.props.library
    this.setState({
      data,
      publicLibrary,
      orgLibrary,
      isFetching
    })
    if (libraries.length > 0) {
      await this.getMediaDetail()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.setState({
      nav: this.slider
    })
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  getMediaDetail = () => {
    const { match } = this.props
    const {
      data: { rows: libraries }
    } = this.state
    const {
      params: { id: mediaId }
    } = match
    let index = _.findIndex(libraries, ({ id }) => id.toString() === mediaId)
    if (index < 0) {
      index = 0
    }
    const ele = ReactDom.findDOMNode(this.sliderContainer.current)
    if (ele) {
      ele.scrollLeft = index * 70
    }
    if (!_.isEmpty(libraries)) {
      this.getSelectedIndex(index)
      this.slider.goTo(index)
      this.setState(
        {
          detail: libraries[index],
          description: libraries[index].description,
          topics: libraries[index].topics,
          keywords: libraries[index].keywords,
          index
        },
        () => {
          this.getOldTopics(libraries[index].topics)
        }
      )
    }
  }

  changeIndex = index => {
    const {
      data: { rows: libraries }
    } = this.state
    this.slider.goTo(index)
    this.getSelectedIndex(index)
    this.setState(
      {
        detail: libraries[index],
        index,
        description: libraries[index].description,
        topics: libraries[index].topics,
        keywords: libraries[index].keywords
      },
      () => {
        this.getOldTopics(libraries[index].topics)
      }
    )
  }
  changeMedia = type => {
    const { index } = this.state
    const {
      data: { rows: libraries }
    } = this.state
    let newIndex = 0
    if (type === 'previous') {
      this.onScrollTimeline(0)
      if (index > 0) {
        newIndex = index - 1
      } else {
        newIndex = libraries.length - 1
      }
    } else if (type === 'next') {
      this.onScrollTimeline(1)
      if (index < libraries.length - 1) {
        newIndex = index + 1
      } else {
        newIndex = 0
      }
    }
    this.slider.goTo(newIndex)
    this.getSelectedIndex(newIndex)
    this.setState(
      {
        index: newIndex,
        detail: libraries[newIndex],
        description: libraries[newIndex].description,
        topics: libraries[newIndex].topics,
        keywords: libraries[newIndex].keywords
      },
      () => {
        this.getOldTopics(libraries[newIndex].topics)
      }
    )
  }

  getSelectedIndex = indexSelected => {
    const {
      data: { rows: libraries }
    } = this.state
    const newLibraries = _.filter(libraries, (item, index) => {
      if (indexSelected === index) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }
      return item
    })
    this.setState({
      data: {
        rows: newLibraries
      }
    })
  }

  getTopic = () => {
    const { value } = this.state
    const mediaTopic = _.map(value, 'label')
    return mediaTopic
  }
  getKeywords = keywords => (keywords && keywords.split(',')) || []

  handleSaveDetail = () => {
    const {
      description,
      keywords,
      detail: { id },
      value
    } = this.state
    const data = {
      description,
      topics: _.map(value, 'id'),
      keywords
    }
    updateMedia(id, data).then(() => {
      this.setState({
        isChangeTopics: false
      })
    })
  }

  updateNewDescription = () => {
    const {
      data: { rows: libraries },
      detail: { id },
      description,
      keywords,
      value
    } = this.state
    const newLibraries = _.map(libraries, library => {
      if (library.id === id) {
        library.description = description
        library.topics = _.map(value, 'id')
        library.keywords = keywords
      }
      return library
    })
    this.setState({
      data: {
        rows: newLibraries
      }
    })
  }

  editTags = (type, status) => {
    const disableEdit = this.disableEdit()
    if (disableEdit) {
      return
    }
    if (type === general.TOPICS) {
      this.setState({
        isEditTopic: status,
        inputValue: ''
      })
    } else if (type === general.KEYWORD) {
      this.setState({
        isEditKeyword: status
      })
    } else if (type === general.DESC) {
      this.setState({
        disable: !status
      })
    }
    const isChange = this.getChangesObj()
    if (!status && isChange) {
      this.updateNewDescription()
      this.handleSaveDetail()
    }
  }
  getChangesObj = () => {
    const { description, keywords, detail, isChangeTopics } = this.state
    const { description: oldDes, keywords: oldKeywords } = detail
    return description !== oldDes || keywords !== oldKeywords || isChangeTopics
  }

  handleChangeDescription = e => {
    const description = e.target.value
    this.setState({ description })
  }

  handleChangeKeyword = value => {
    const keywords = value.join(',')
    this.setState({ keywords })
  }

  handleGoBack() {
    history.replace('/library/list')
  }

  getCreatorName = creator => {
    if (creator) {
      return creator.username
    }
    return ''
  }
  getImageURL = library => {
    if (library) {
      const { link, resizes } = library
      const thumbnail = resizes && resizes.thumbnail
      if (thumbnail) {
        return thumbnail
      }
      return link
    }
    return null
  }
  onScrollTimeline = f => {
    const {
      data: { rows: libraries },
      index
    } = this.state
    const ele = ReactDom.findDOMNode(this.sliderContainer.current)
    let pos = 0
    let move = setInterval(() => {
      if (pos === 1) {
        clearInterval(move)
      } else {
        if (f === 1 && index === libraries.length - 1) {
          pos++
          ele.scrollLeft = -(libraries.length * 100)
        } else if (f === 0 && index === 0) {
          pos++
          ele.scrollLeft = libraries.length * 100
        } else {
          pos++
          ele.scrollLeft += f ? 100 : -100
        }

        clearInterval(move)
      }
    }, 100)
  }
  handleDownload = () => {
    const {
      detail: { media_format, media_mime_type }
    } = this.state
    if (media_format === types.IMAGE && media_mime_type !== types.IMAGEGIF) {
      this.setState({
        isDownload: true
      })
    } else {
      this.handleSaveMedia()
      this.setState({
        isDownloading: true
      })
    }
  }
  handleSaveMedia = size => {
    const {
      detail: { id, filename }
    } = this.state
    const { download } = this.props
    const data = {
      id,
      size
    }
    download(data).then(response => {
      fileSaver.saveAs(response.downloadble_link, filename)
      this.setState({
        isDownloading: false
      })
    })
  }

  downloadImage = size => {
    this.handleSaveMedia(size)
    this.setState({
      isDownload: false,
      isDownloading: true
    })
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isDownload: false
      })
    }
  }

  disableDownload = () => {
    const {
      location: { search: queryUri },
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    const { isPublic } = queryString.parse(queryUri)
    return isPublic === 'true' ? isSuperAdmin : true
  }

  disableEdit = () => {
    const {
      location: { search: queryUri }
    } = this.props
    const { isPublic } = queryString.parse(queryUri)
    return isPublic === 'true'
  }

  getOldTopics = topics => {
    const {
      topics: { list: topicList, isFetching }
    } = this.props
    if (!isFetching) {
      const topic = topics.map(item =>
        _.head(topicList.filter(topic => topic.id === item))
      )
      if (!_.isEmpty(topic)) {
        const newValue = topic.map(item => item && this.createOption(item))
        this.setState({
          value: newValue
        })
      }
    }
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState({
        value: newValue,
        inputValue: '',
        isTopicLoading: false,
        isTopicsMax: false,
        isChangeTopics: true
      })
    } else if (!_.isEmpty(newValue) && newValue.length > 3) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState({
        value: newValue,
        inputValue: '',
        isTopicLoading: false,
        isTopicsMax: false,
        isChangeTopics: true
      })
    }
    this.isModalSelectOpen()
  }
  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState({
            isTopicLoading: false,
            options: [...options, newOption],
            isTopicsMax: false,
            value: [...value, newOption],
            inputValue: ''
          })
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    if (!topic) {
      return
    }
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  checkIsPPT = (fileMimeType = '') =>
    fileMimeType.includes('powerpoint') || fileMimeType.includes('presentation')

  copyToClipboard = link => {
    navigator.clipboard.writeText(link.toString())
    message.success('Link copied to clipboard.')
  }

  checkIsPDF = (fileMimeType = '') => fileMimeType.includes('pdf')

  checkIsDocx = type =>
    type ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

  render() {
    const { t, user } = this.props
    const {
      isFetching,
      disable,
      isEditTopic,
      isEditKeyword,
      description,
      isDownload,
      isDownloading,
      data: { rows: libraries },
      detail: {
        title,
        filename,
        media_format,
        file_size,
        media_mime_type,
        topics,
        keywords,
        creator,
        is_ai_enabled,
        link
      },
      value,
      options,
      isTopicLoading,
      isTopicsMax
    } = this.state

    console.log('this.state ', this.state)

    const disableEdit = this.disableEdit()

    const topicsArr = this.getTopic(topics)

    const keywordsArr = this.getKeywords(keywords)

    const userName = this.getCreatorName(creator)

    const enableDownload = this.disableDownload()

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }

    const customStyles = {
      control: provided => ({
        ...provided,
        borderRadius: '0px',
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
        marginBottom: isTopicsMax ? '0px' : '24px'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }

    const isKnowledgeCenter = this.props.location.pathname.includes(
      'knowledge-center'
    )

    const backToLibraryURL = isKnowledgeCenter
      ? '/knowledge-center/library/list'
      : '/library/list'

    if (_.isEmpty(libraries) && !isFetching) {
      return (
        <div>
          <TopPageLink
            page={t('buttons:from_library')}
            path={backToLibraryURL}
          />
          <EmptyMessage
            title={t('general:something_went_wrong')} // require title
            subTitle={t('media:media_not_found')} // require subTitle
            buttonTitle={t('buttons:back_to_media')} // require buttonTitle
            onClickAction={this.handleGoBack} // require onClickAction optional
          />
        </div>
      )
    }
    return (
      <div style={{ margin: 0, padding: 0 }}>
        {isFetching ? (
          <Spinner />
        ) : (
          <div className="contain">
            <TopPageLink
              page={t('buttons:from_library')}
              path={backToLibraryURL}
            />
            <div className="media_container">
              {enableDownload && (
                <div className="download_icon" onClick={this.handleDownload}>
                  {isDownloading ? (
                    <Icon
                      type="loading"
                      twoToneColor="#0068FF"
                      style={{ fontSize: '30px' }}
                    />
                  ) : (
                    <Tooltip title={t('v4:fileDownload')}>
                      <DownloadOutlined style={{ fontSize: 20 }} />
                    </Tooltip>
                  )}
                </div>
              )}
              <div className="media_preview">
                <div
                  className="media_preview_key left_key"
                  onClick={() => this.changeMedia('previous')}
                >
                  <Icon type="left" />
                </div>
                <Carousel
                  dots={false}
                  ref={slider => (this.slider = slider)}
                  lazyLoad
                >
                  {libraries.map((library, index) => (
                    <Preview library={library} key={index} />
                  ))}
                </Carousel>
                <div
                  className="media_preview_key right_key"
                  onClick={() => this.changeMedia('next')}
                >
                  <Icon type="right" />
                </div>
              </div>
              <div className="slider_container">
                <ScrollContainer
                  className="scroll-container"
                  ref={this.sliderContainer}
                >
                  {libraries.map((library, index) => (
                    <div
                      className={`slide_info ${library.isSelected && 'active'}`}
                      key={index}
                      onClick={() => this.changeIndex(index)}
                    >
                      {(library.media_format === types.IMAGE ||
                        library.media_format === types.ARIMAGE ||
                        library.media_format === types.ICON ||
                        library.media_format === types.LOGO ||
                        library.media_format === types.CERTIFICATE ||
                        library.media_format === types.BADGE ||
                        library.media_format === 'GAME') && (
                        <img src={this.getImageURL(library)} alt="" />
                      )}
                      {(library.media_format === types.VIDEO ||
                        library.media_format === types.VIDEO_ANTMEDIA ||
                        library.media_format === types.VIDEO_STREAM) && (
                        <Icon size="large" type="play-circle" theme="filled" />
                      )}
                      {library.media_format === types.AUDIO && (
                        <Icon type="play-circle" size="large" />
                      )}
                      {library.media_format === types.TEXT && (
                        <img src={textIcon} alt="" />
                      )}
                      {library.media_format === types.APPLICATION && (
                        <img
                          src={
                            this.checkIsDocx(library.media_mime_type)
                              ? textIcon
                              : this.checkIsPPT(library.media_mime_type)
                              ? pptImage
                              : pdfImage
                          }
                          alt="file"
                        />
                      )}
                    </div>
                  ))}
                </ScrollContainer>
              </div>
            </div>
            <div className="media_detail">
              <div className="wrapper">
                <div className="left_content">
                  <div className="file_name">
                    {filename || title}
                    <Tooltip title="Copy Link">
                      <Icon
                        type="link"
                        onClick={() => this.copyToClipboard(link)}
                      />
                    </Tooltip>
                  </div>
                  <div className="file_detail">
                    <span className="media_tag">{media_format}</span>
                    <span className="extension">
                      {this.checkIsDocx(media_mime_type)
                        ? 'docx'
                        : this.checkIsPPT(media_mime_type)
                        ? 'ppt'
                        : this.checkIsPDF(media_mime_type)
                        ? 'pdf'
                        : media_mime_type && media_mime_type.split('/')[1]}{' '}
                    </span>
                    <div className="size">{bytesToSize(file_size)}</div>
                  </div>
                </div>
                <div className="right_content">
                  <div className="description">{t('labels:description')}</div>

                  {!disable && (
                    <div className="des_container">
                      <TextArea
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        readOnly={disable}
                        autoFocus={!disable}
                        maxLength="500"
                        value={description}
                        onChange={this.handleChangeDescription}
                        onBlur={() => this.editTags('description', false)}
                      />
                    </div>
                  )}
                  {disable && (
                    <div
                      className={`media_des ${!disableEdit && 'hoverStyle'}`}
                      onClick={() => this.editTags('description', true)}
                    >
                      {description ? (
                        <div>{description}</div>
                      ) : (
                        <div className="empty_label">
                          {t('placeholders:no_description')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="wrapper">
                <div className="left_content">
                  <div className="topic">{t('labels:topic')}</div>
                  <div
                    style={{ width: '100%' }}
                    onClick={() => this.editTags('topics', true)}
                  >
                    {isEditTopic && (
                      <div className="des_container">
                        <CreatableSelect
                          styles={customStyles}
                          components={{
                            ClearIndicator: null,
                            DropdownIndicator: null,
                            LoadingIndicator: this.LoadingIndicator
                          }}
                          isMulti
                          placeholder=""
                          inputId="react-select-2-input"
                          isLoading={isTopicLoading}
                          menuIsOpen={this.isModalSelectOpen()}
                          onChange={this.handleChange}
                          onCreateOption={this.handleCreate}
                          onInputChange={this.loadOptions}
                          options={options}
                          value={value}
                          autoFocus={isEditTopic}
                          onBlur={() => this.editTags('topics', false)}
                        />
                        {isTopicsMax && (
                          <div className="error_message">
                            {t('errors:topics_max')}
                          </div>
                        )}
                      </div>
                    )}
                    {!isEditTopic && (
                      <div
                        className={`file_topic ${!disableEdit && 'hoverStyle'}`}
                      >
                        {topicsArr.length > 0 ? (
                          topicsArr.map((item, index) => (
                            <span key={index}>{item}</span>
                          ))
                        ) : (
                          <div className="empty_label">
                            {t('placeholders:no_topics')}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="uploaded_by">
                    <span>{t('labels:uploaded_by')}</span>
                    <span id="uploader">{userName}</span>
                  </div>
                </div>
                <div className="right_content">
                  <div className="keywords">{t('placeholders:keyword')}</div>
                  <div onClick={() => this.editTags('keywords', true)}>
                    {isEditKeyword && (
                      <div className="des_container">
                        <Select
                          onBlur={() => this.editTags('keywords', false)}
                          style={{ width: '100%' }}
                          mode="tags"
                          autoFocus
                          className="custom-select-tag"
                          dropdownClassName="disable-select-dropdown"
                          placeholder={t('placeholders:no_keyword')}
                          onChange={this.handleChangeKeyword}
                          defaultValue={keywordsArr}
                          tokenSeparators={[',', '.', ';']}
                        />
                      </div>
                    )}
                    {!isEditKeyword && (
                      <div
                        className={`file_keywords ${!disableEdit &&
                          'hoverStyle'}`}
                      >
                        {keywordsArr.length > 0 ? (
                          keywordsArr.map((item, index) => (
                            <span key={index}>{item}</span>
                          ))
                        ) : (
                          <div className="empty_label">
                            {t('placeholders:no_keyword')}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="uploaded_by">
                      {user.info.organization &&
                        user.info.organization.enable_ai &&
                        is_ai_enabled && (
                          <div className="media-card__date-container">
                            <span className="media-card__date">
                              <Checkbox
                                className="disabled-checkbox"
                                defaultChecked={is_ai_enabled}
                                disabled
                              />
                              &nbsp; {t('v4:ai')}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isDownload && (
          <div className="modal_wrapper" ref={this.setWrapperRef}>
            <div className="arrow-up" />
            <div className="download-modal">
              <div className="download_title">Download</div>
              {downloadSize.map(size => (
                <div
                  className="download_des"
                  onClick={() => this.downloadImage(size.type)}
                >
                  {size.title}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(withTranslation('media')(Gallery))
