import React, { Component, Fragment } from 'react'

import CourseCreateTopPanel from '../Create/internal/CourseCreateTopPanel'
import CourseCreateRoutes from '../Create/routes'
import history from '../../../history'

const courseFieldMatrix = course => {
  const {
    title,
    topics,
    cover,
    cover_id: coverId,
    marketplace = {},
    description,
    organiser,
    cert_id,
    badge_id,
    start_date: startDate,
    exp_date: expDate,
    pass_score,
    course_type
  } = course
  return {
    courseTitle: title,
    courseOrganiser: organiser,
    courseTags: topics || [],
    courseImgId: coverId,
    courseType: course_type,
    courseCertId: cert_id,
    courseBadgeId: badge_id,
    courseImg: coverId && cover.link,
    courseFileName: coverId && cover.filename,
    courseDescription: description || '',
    coursePassScore: pass_score,
    courseCategory: marketplace.category_id,
    courseIsFree: marketplace.is_free,
    coursePrice: marketplace.price,
    courseVideoLink: marketplace.video_link,
    courseNoOfVideos: marketplace.no_of_video,
    courseNoOfLessons: marketplace.no_of_lesson,
    courseOrganization: marketplace.organization_id,
    courseGroup: marketplace.group_id,
    courseObjectives: marketplace.course_obj,
    courseOutline: marketplace.course_outline,
    courseMarketPlaceDescription: marketplace.course_description,
    courseTrainerLinkedIn: marketplace.linkedin,
    courseTrainerBio: marketplace.bio,
    courseStartDate: startDate,
    courseExpDate: expDate
  }
}

class CourseEdit extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      getCourseById,
      courseBuilderActions: { handleCourseInput }
    } = this.props

    handleCourseInput('courseId', id)

    getCourseById(id).then(response => {
      if (!response.is_access) {
        history.push(`/scorm/${id}`)
        return
      }
      const data = courseFieldMatrix(response)
      Object.keys(data).forEach(key => handleCourseInput(key, data[key]))
    })
  }

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props

    const mode = 'edit'
    return (
      <Fragment>
        <CourseCreateTopPanel mode={mode} id={id} />
        <CourseCreateRoutes mode={mode} />
      </Fragment>
    )
  }
}

export default CourseEdit
