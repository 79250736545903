import React from 'react'

import { withTranslation } from 'react-i18next'
import './CreateChannelHeader.scss'
import { Button } from 'antd'
import NewHeader from '../../../../../components/NewHeader'

function CreateChannelHeader({
  t,
  isMarketplaceView,
  onToggleMarketplace,
  channelId
}) {

  return (
    <div className="shl-create-channel-header">
      <h1 className="shl-create-channel-header-title">
        {t('v3:create_a_new_channel')}
      </h1>

      <div class="marketplace-head">
        {!isMarketplaceView && <Button
          size="large"
          shape="round"
          className="button-blue_color"
          onClick={onToggleMarketplace}
          disabled={!channelId}
        >
          {t('buttons:market_place').toUpperCase()}
        </Button>}
        <NewHeader />
      </div>
    </div>
  )
}

export default withTranslation()(CreateChannelHeader)
