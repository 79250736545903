import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Input } from 'antd'
import { CustomSwitch } from './CustomSwitch'

const PriceManagement = props => {
  const {
    t,
    form: { getFieldDecorator, setFieldsValue, validateFields },
    onPrice,
    courseBuilder: { coursePrice, courseIsFree, coursePriceToEdit },
    handleSwitch,
    onPriceToEdit,
    onEditPermission
  } = props

  const [canUserEdit, setCanUserEdit] = useState(false)

  useEffect(() => {
    if (coursePriceToEdit && !canUserEdit) {
      setCanUserEdit(true)
    }
  }, [coursePriceToEdit])
  useEffect(() => {
    if (courseIsFree) {
      setCanUserEdit(false)
      onEditPermission(false)
      setTimeout(() => {
        setFieldsValue({ priceToEdit: 1, price: 5 })
        onPriceToEdit(1)
        onPrice(5)
        validateFields()
      }, 300);
    }
  }, [courseIsFree])

  const onChange = e => {
    const isChecked = e.target.checked
    setCanUserEdit(isChecked)
    onEditPermission(isChecked)
  }

  const initialPriceValue = Number(coursePrice) !== 0 && !Number(coursePrice) ? 5 : Number(coursePrice)
  const initialPriceToEditValue = !coursePriceToEdit ? 1 : Number(coursePriceToEdit)
  console.log('test prices', Number(coursePrice), Number(initialPriceValue), Number(initialPriceToEditValue))

  return (
    <>
      <div className="div_row">
        <Form.Item colon={false} required={false} className="price_style">
          <div
            style={{
              marginBottom: '4px',
              fontSize: '1.2rem',
              lineHeight: '1.5',
            }}
          >
            <Checkbox
              disabled={courseIsFree}
              checked={canUserEdit}
              onChange={onChange}
            >
              {t('v3:edit_permission').toUpperCase()}
            </Checkbox>
          </div>
          {getFieldDecorator('priceToEdit', {
            initialValue: initialPriceToEditValue,
            rules: [
              {
                validator: (rule, value, cb) => {
                  if (value > 0) {
                    cb();
                  } else {
                    cb(t('v3:valid_price'));
                  }
                },
                message: t('v3:valid_price'),
              },
              {
                validator: (rule, value, cb) => {
                  if (Number(value) < Number(coursePrice)) {
                    cb();
                  } else {
                    !courseIsFree && cb(t('v3:valid_edit_price'));
                  }
                },
                message: t('v3:valid_edit_price'),
              },
            ],
          })(
            <Input
              size="large"
              type="number"
              min={1}
              maxLength={100}
              onChange={e => onPriceToEdit(e.target.value)}
              disabled={!canUserEdit}
            />
          )}
        </Form.Item>


        <Form.Item colon={false} required={false}>
          <div className="isMarket-place-price">
            {getFieldDecorator('is_free', {
              initialValue: courseIsFree
            })(
              <CustomSwitch
                label="Free Course"
                onChanged={e => handleSwitch(e)}
              />
            )}
          </div>
        </Form.Item>
        <Form.Item
          colon={false}
          required={false}
          className="price_style"
          label={t('labels:price (usd)')}
        >
          {getFieldDecorator('price', {
            initialValue: initialPriceValue,
            rules: [
              {
                validator: (rule, value, cb) => value >= 5,
                message: t('v4:enter_price'),
              }
            ]
          })(
            <Input
              size="large"
              type="number"
              min={5}
              maxLength={100}
              onChange={e => onPrice(e.target.value)}
              disabled={courseIsFree}
            />
          )}
        </Form.Item>
      </div>
      <div className="red_text">{t('v3:course_price_message')}</div>
    </>
  )
}

export default PriceManagement
