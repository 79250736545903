import React from 'react'
import { Collapse, Modal, Button, Tooltip } from 'antd'
import styled from 'styled-components'
import Attachment from '../../../../Events/Create/components/Attachment'
import { useTranslation } from 'react-i18next'
import { PaperClipOutlined } from '@ant-design/icons'
import { general } from '../../../../../constants'
import { bytesToSize } from '../../../../../utils'
import className from 'classnames'
import Dropzone from 'react-dropzone'
import { PopupMedia } from '../../../../Library/internal'
import Preview from '../../../../Library/components/Preview'
const CollapseContainer = styled(Collapse)`
  && {
    & .ant-collapse-item {
      border: 0;
      margin-bottom: 0.5rem;

      & .ant-collapse-content {
        & .ant-collapse-content-box {
          padding: 0 3rem 1.5rem 2.3rem;
          display: block !important;
        }
      }
    }
  }
`
CollapseContainer.Panel = styled(Collapse.Panel)``
const AttachFile = ({
  modalOpen,
  selectedItem,
  hidePreviewModal,
  handleFileInsert,
  handleInsertFile,
  handleRemoveSelected,
  filenames,
  pdfFiles,
  onHandleDelete,
  attachmentIds,
  checkedCompletUpload,
  library: { uploadProgress, uploadSuccess },
  onDropPdf,
  toggleMediaModal,
  attachModal,
  setAttachModal
}) => {
  const { t } = useTranslation()
  return (
    <div style={{ marginBottom: '1.2rem' }}>
      {modalOpen && (
        <Modal
          visible={modalOpen}
          onCancel={toggleMediaModal}
          footer={null}
          width={'80%'}
          style={{
            overflow: 'auto',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }}
        >
          <div className="wrap_modal_tabs">
            <PopupMedia
              isPageBuilder
              types="pdf"
              handleInsertData={handleInsertFile}
              handleInsertFile={handleInsertFile}
              handleRemoveSelected={handleRemoveSelected}
            />
          </div>
          {selectedItem && (
            <Modal
              visible={selectedItem}
              onCancel={hidePreviewModal}
              footer={null}
            >
              <Preview library={selectedItem} />
              <div className="wrap_btn_actions">
                <Button
                  type="danger"
                  size="large"
                  className="action_button"
                  onClick={hidePreviewModal}
                >
                  {t('labels:cancel')}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="action_button"
                  onClick={() => handleFileInsert(selectedItem)}
                >
                  {t('labels:insert')}
                </Button>
              </div>
            </Modal>
          )}
        </Modal>
      )}
      {attachModal && (
        <Modal
          title={t('v4:select_file_attach')}
          visible={attachModal}
          onCancel={() => {
            setAttachModal(false)
          }}
          className="custom-ant-modal"
          destroyOnClose
          width={600}
          footer={null}
          // width={'40%'}
          // style={modal_style}
        >
          <Dropzone accept={'.pdf'} onDrop={onDropPdf} style={{}} multiple>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                className={className(
                  'upload-dropzone',
                  'upload-dropzone_event',
                  {
                    'upload-dropzone_active': isDragActive
                  }
                )}
              >
                <div className="wrap_upload_buttons">
                  <Button
                    className="rounded"
                    size="large"
                    icon="filepdfoutlined"
                  >
                    {t('v4:upload_pdf')}
                  </Button>
                  <Button
                    className="rounded"
                    size="large"
                    icon="filepdfoutlined"
                    onClick={e => {
                      e.stopPropagation()
                      toggleMediaModal()
                    }}
                  >
                    {t('buttons:select_media_library')}
                  </Button>
                </div>
              </div>
            )}
          </Dropzone>
        </Modal>
      )}
      <div className="cover-title">{t('v4:file_attached')}</div>

      <CollapseContainer
        bordered={false}
        accordion={true}
        expandIconPosition={'right'}
        className="attach_file_panel"
      >
        <CollapseContainer.Panel
          header={
            <Tooltip placement="top" arrow title={t('v4:attach_file')}>
              <div
                className="attach_file_button"
                onClick={e => {
                  e.preventDefault()
                  setAttachModal(true)
                }}
              >
                <span role="button">
                  <PaperClipOutlined />
                </span>
                <span>
                  {t('v4:to_attach')}{' '}
                  {attachmentIds.length > 0 && <>({attachmentIds.length})</>}
                </span>
              </div>
            </Tooltip>
          }
        >
          {filenames.length > 0 && (
            <Attachment data={filenames} t={t} handleDelete={onHandleDelete} />
          )}
        </CollapseContainer.Panel>
      </CollapseContainer>
      {uploadProgress.length > 0 &&
        uploadSuccess.length !== uploadProgress.length &&
        uploadProgress.map((item, ind) =>
          item.percent < 100 ? (
            <div key={item.uid} className="upload-modal__item">
              <div className="upload-modal-item">
                <div className="upload-modal-item__percent-border">
                  <div
                    className={`upload-modal-item__percent ${
                      item.status === general.CANCELED
                        ? 'upload-modal-item__percent__cancel'
                        : ''
                    }`}
                    style={{ width: item.percent + '%' }}
                  />
                </div>
              </div>

              {item.status !== general.CANCELED && (
                <div className="upload-modal-item__weight">
                  {checkedCompletUpload(item) && (
                    <span>
                      {bytesToSize(item.loaded)} {t('of')}{' '}
                      {bytesToSize(item.total)}
                    </span>
                  )}
                  {!checkedCompletUpload(item) && (
                    <span className="upload-modal-item__weight__complete">
                      {pdfFiles[ind] && pdfFiles[ind].removed
                        ? t('general:removed')
                        : t('general:completed')}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : null
        )}
    </div>
  )
}
export default AttachFile
