import React from 'react'
// import { Input } from 'antd'
import './Description.scss'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const Description = ({ t, setDescription, description }) => {
  // const onDescriptionChange = e => {
  //   setDescription(e.target.value)
  // }

  return (
    <div className="decription">
      <div className="decription__content">
        <div className="ant-row ant-form-item">
          <div className="description-input">
            <div className="description-input-title">
              {t('v3:podcast_description')}
            </div>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData()
                setDescription(data)
              }}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'blockQuote',
                  'link',
                  'numberedList',
                  'bulletedList',
                  'undo',
                  'redo'
                ],
                removePlugins: [
                  'Image',
                  'ImageUpload',
                  'MediaEmbed',
                  'InsertImage',
                  'EasyImage',
                  'Video'
                ]
              }}
            />
            {/* <Input.TextArea
              value={description}
              placeholder={t('v3:podcast_description')}
              onChange={onDescriptionChange}
              rows={3}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Description
