import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import QuizHandler from './QuizHandler'

import * as quizesActions from '../../../../store/actions/quizes'
import * as libraryActions from '../../../../store/actions/library'

export default connect(
  state => ({
    quizes: state.quizes,
    pageBuilder: state.pageBuilder,
    lessonBuilder: state.lessonBuilder,
    library: state.library
  }),
  dispatch => ({
    quizesActions: bindActionCreators(quizesActions, dispatch),
    libraryActions: bindActionCreators(libraryActions, dispatch)
  })
)(QuizHandler)
