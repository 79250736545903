/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-15 16:02:19
 * @LastEditors: Please set LastEditors
 */
import uuidv4 from 'uuid/v4'
import { Buffer } from 'buffer'
import _ from 'lodash'
import axios from 'axios'
import notification from '../../views/Library/components/notification'
import { api } from '../../services'
import {
  CLEAR_UPLOAD_LIST,
  DOWNLOAD_FETCHING,
  DOWNLOAD_SUCCESS,
  HANDLE_MAKE_TYPE,
  LIBRARY_FETCHING,
  LIBRARY_SUCCESS,
  PUBLIC_FETCHING,
  PUBLIC_SUCCESS,
  PUT_FILE_UPLOAD_PROGRESS,
  REMOVE_UPLOAD_SUCCESS,
  UPDATE_LIBRARY_STATUS,
  UPDATE_STATUS_UPLOAD,
  UPLOAD_FETCHING,
  UPLOAD_SUCCESS,
  ORG_SUCCESS,
  ORG_FETCHING,
  UPDATE_ORG_LIB,
  UPDATE_ORG_LIB_SUCCESS
} from '../types'

const getLibraryList = query => async dispatch => {
  dispatch({
    type: LIBRARY_FETCHING,
    payload: true
  })

  const response = await api.library.getLibraryList(query)
  dispatch({
    type: LIBRARY_SUCCESS,
    payload: response.data
  })
  dispatch({
    type: LIBRARY_FETCHING,
    payload: false
  })
  return response.data
}

const getPublicList = query => async dispatch => {
  dispatch({
    type: PUBLIC_FETCHING,
    payload: true
  })

  const response = await api.library.getPublicList(query)
  dispatch({
    type: PUBLIC_SUCCESS,
    payload: response.data
  })
  dispatch({
    type: PUBLIC_FETCHING,
    payload: false
  })
  return response.data
}

const getOrgList = query => async dispatch => {
  dispatch({
    type: ORG_FETCHING,
    payload: true
  })

  const response = await api.library.getOrgLibrary(query)
  dispatch({
    type: ORG_SUCCESS,
    payload: response.data
  })
  dispatch({
    type: ORG_FETCHING,
    payload: false
  })
  return response.data
}

const clearUploadList = () => dispatch => {
  dispatch({
    type: CLEAR_UPLOAD_LIST
  })
}

const getCancelToken = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

const dispatchResult = data => dispatch => {
  dispatch({
    type: UPLOAD_SUCCESS,
    payload: data
  })
  dispatch({
    type: UPLOAD_FETCHING,
    payload: false
  })
  const { media_format } = data
  // const header_first = title ? _.head(title.split('.')) : ''
  // const header_second = title ? _.last(title.split('.')) : ''
  // const custom_title = `${header_first.substr(0, 15)}.${header_second}`
  const display_title = `Your trigger image and 3D files have been uploaded successfully.`
  notification(media_format, 'success', display_title, 600)
}

const upload = (file, options = {}) => dispatch => {
  dispatch({
    type: UPLOAD_FETCHING,
    payload: true
  })

  const {
    title,
    description,
    isPublic,
    fileUid,
    isAR,
    category,
    isSimulation,
    ai_language,
    isAiEnabled
  } = options
  console.log('categoryuploadaction', category)
  const {
    name: filename,
    type,
    size,
    customData,
    contentId,
    imgDesc,
    imgFormat,
    format
  } = file

  console.log('LOG FILE | OPTIONS: ', file, options)

  const data = { type, isPublic, filename }

  let key = ''

  const uid = uuidv4()

  // generate source token for cancel request
  const source = getCancelToken()

  dispatch({
    type: PUT_FILE_UPLOAD_PROGRESS,
    payload: {
      uid,
      name: filename,
      percent: 0.3,
      loaded: 0,
      total: size,
      source, // store source token for cancel request
      fileUid,
      category
    }
  })

  return api.library
    .sign(data, isAR, category)
    .then(response => {
      let newFile = null
      const { link } = response.data
      key = response.data.key

      const opts = {
        headers: { 'Content-Type': type },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded * 100) / total)
          dispatch({
            type: PUT_FILE_UPLOAD_PROGRESS,
            payload: {
              uid,
              fileUid,
              name: filename,
              percent,
              loaded,
              total,
              key,
              source,
              category
            }
          })
        }
      }

      if (file.croppedImage) {
        newFile = Buffer.from(
          file.croppedImage.replace(/^data:image\/\w+;base64,/, ''),
          'base64'
        )
      } else {
        newFile = file
      }

      opts.cancelToken = source.token

      const responeUpload = api.library.putToS3(link, newFile, opts)

      responeUpload.catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          dispatch({
            type: UPDATE_STATUS_UPLOAD,
            payload: {
              uid,
              key,
              status: 'CANCELED'
            }
          })
        }
      })

      return responeUpload
    })
    .then(() => {
      let imageDescription = imgDesc || description
      let imageFormat =
        isSimulation && !format
          ? 'GAME'
          : imgFormat || type.split('/')[0].toUpperCase()
      const media = {
        title,
        description: imageDescription,
        filename,
        type: isSimulation && !type ? 'GAME' : type,
        key,
        format: imageFormat,
        customData,
        contentId,
        isAR,
        isPublic,
        category,
        ...(ai_language && isAiEnabled && { ai_language, isAiEnabled })
      }

      const isScorm = category === 'scorm'
      if (isAR === 'imgAR' || isAR === 'zipAR') {
        return media
      } else {
        return api.library.upload(media, {
          isScormZipUpload: isScorm
        })
      }
    })
    .then(data => {
      console.log('RESPONSE DATA CHECKPOINT', data)
      if (data === undefined) {
        return
      }

      if (isAR === 'imgAR' || isAR === 'zipAR') {
        return data
      }

      dispatch({
        type: UPLOAD_SUCCESS,
        payload: data.data
      })
      dispatch({
        type: UPLOAD_FETCHING,
        payload: false
      })

      if (category === 'scorm') {
        notification(
          'scorm',
          'success',
          'Your scorm file has been uploaded successfully.',
          600
        )
        return data.data
      }

      const { media_format, title } = data.data
      const header_first = title ? _.head(title.split('.')) : ''
      const header_second = title ? _.last(title.split('.')) : ''
      const custom_title = `${header_first.substr(0, 15)}.${header_second}`
      const display_title = `Your ${media_format.toLowerCase()} ${custom_title} has been uploaded.`
      notification(media_format, 'success', display_title, 600)
      return data.data
    })
}

const updateLibraryStatus = query => async dispatch => {
  dispatch({
    type: UPDATE_LIBRARY_STATUS,
    payload: false
  })

  const response = await api.library.updateLibraryStatus(query)
  const status = response.status === 200
  dispatch({
    type: UPDATE_LIBRARY_STATUS,
    payload: status
  })

  return status
}

const updateOrgLib = query => async dispatch => {
  dispatch({
    type: UPDATE_ORG_LIB,
    payload: false
  })

  const response = await api.library.updateOrgLibrary(query)
  const status = response.status === 200
  dispatch({
    type: UPDATE_ORG_LIB_SUCCESS,
    payload: status
  })

  return status
}

const handleMakeType = type => {
  return dispatch => {
    dispatch({
      type: HANDLE_MAKE_TYPE,
      makeType: type
    })
  }
}

const removeFromUploadingList = fileUid => {
  return dispatch => {
    dispatch({
      type: REMOVE_UPLOAD_SUCCESS,
      payload: fileUid
    })
  }
}

const download = id => async dispatch => {
  dispatch({
    type: DOWNLOAD_FETCHING,
    payload: true
  })

  const response = await api.library.download(id)
  dispatch({
    type: DOWNLOAD_SUCCESS,
    payload: response.data
  })
  dispatch({
    type: DOWNLOAD_FETCHING,
    payload: false
  })
  return response.data
}

export {
  getLibraryList,
  getPublicList,
  upload,
  clearUploadList,
  dispatchResult,
  updateLibraryStatus,
  handleMakeType,
  download,
  removeFromUploadingList,
  getOrgList,
  updateOrgLib
}
